import React, { Component } from 'react';
import {
    IonContent, IonDatetime, IonIcon,
    IonText, IonCard, IonImg, 
} from '@ionic/react';

import {  thunderstormOutline } from 'ionicons/icons';
import { chooseColor } from '../../../../functions/function';
import BackButton from '../../../BackButton';

var date = new Date();
var today = date.toISOString();
var today2 = today.substr(0, 10);
interface weatherDetail {
    wheatherData: any
    returnBack: any
}

class thunderstorm extends Component<weatherDetail>{
    header() {
        return (<div>
            <IonIcon id='todayWeatherIcon' font-weight='bold' icon={thunderstormOutline} />
            <IonText id='weatherTitle'>Risk of thunderstorm asthma</IonText>
        </div>)
    }
    render() {


        return (
            <IonContent id='weatherPage'>
                <BackButton
                    function={this.props.returnBack}
                    arg='thunderstorm'
                    title={this.header()}
                />



                <IonCard id='weatherContent'>

                    <IonDatetime id="date"
                        displayFormat="DDD, DD MMM"
                        value={today} readonly={true} />



                    <IonText className='districtText'>In your district</IonText>
                    <p><IonText className='airQualitySite'>{this.props.wheatherData.district}</IonText> <br></br></p>

                    <p><IonText className='airQualityLevel'
                        style={{
                            color: chooseColor
                                (this.props.wheatherData.thunderstorm_asthma_risk),
                        }}>
                        {this.props.wheatherData.thunderstorm_asthma_risk}
                    </IonText></p> <br></br>
                    <IonImg src={this.props.wheatherData.thunderstorm_asthma_map} ></IonImg> <br></br>
                    <IonText id='shortDescription'>
                        <b>Note:</b> The above epidemic thunderstorm asthma forecast information
                     was provided by the Victorian Department of Health and Human Services
                     and the Bureau of Meteorology. For more information pertaining to these
                     forecasts visit <b><u>this website</u></b>.
                     <br /><br />Last updated: 2019-12-31 03:00:00; Current date: {today2}
                    </IonText>
                </IonCard>
            </IonContent>



        )
    }

}
export default thunderstorm;