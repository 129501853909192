
import {
    IonCard, IonContent, IonToolbar, IonIcon,
    IonInput, IonLabel, IonChip, IonButton, IonToast, IonAlert
} from '@ionic/react';
import { checkmarkOutline, closeOutline, pencilOutline } from 'ionicons/icons';
import React, { Component } from 'react'
import './ProfileDetail.css'
import { getDetail, getInitial, getRandomColor, profileList, selectedProfile, setProfileUpdateSurveyPage, setSelectedProfile } from '../../functions/function'
import BackButton from '../BackButton';
interface profileDetailInterface {
    profile: any,
    closeFunction: any,
    tabName: any,
}

const modifyLink = 'https://portal.dev.pollenforecast.com.au/mobile-app/auth/modify-profile'
const deleteLink = 'https://portal.dev.pollenforecast.com.au/mobile-app/auth/delete-profile'

class ProfileDetail extends Component<profileDetailInterface>{


    state = {
        name: this.props.profile.name,
        sex: this.props.profile.sex,
        age: this.props.profile.age,
        initial: getInitial(this.props.profile.name),
        showToast: false,
        ToastMsg: '',
        deleteWarning: false,
    }

    isChanged = false


    modifyProfile = async () => {

        var changedInfo = {}
        var isChanged = false
        if (this.state.age !== this.props.profile.age) {
            changedInfo['age'] = this.state.age
            isChanged = true
        }
        if (this.state.sex !== this.props.profile.sex) {
            changedInfo['sex'] = this.state.sex
            isChanged = true
        }
        if (this.state.name !== this.props.profile.name) {
            changedInfo['name'] = this.state.name
            isChanged = true
        }
        if (!isChanged) {
            this.setState({
                showToast: true,
                ToastMsg: 'Nothing is changed '
            })
            return
        }
        changedInfo['id'] = this.props.profile.id
        changedInfo['token'] = localStorage.getItem('userToken')

        const response = await fetch(modifyLink, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json'
            },
            body: JSON.stringify(changedInfo)
        });

        const data = await response.json();
        const status = data.status;

        if (status !== "false") {
            this.setState({
                showToast: true,
                toastMsg: 'Successfully change the detail'
            })

            this.syncStoredProfile()
            setProfileUpdateSurveyPage(true)
            if (this.state.name !== this.props.profile.name) {
                this.state.initial = getInitial(this.state.name)
            }

        } else {
            this.setState({
                ToastMsg: data.message,
                showToast: true
            })

        }
    }

    syncStoredProfile() {

        profileList.map((profile, index) => {
            if (profile.id === this.props.profile.id) {
                profile.age = this.state.age
                profile.name = this.state.name
                profile.sex = this.state.sex
            }
        })

    }

    deleteLocalProfile() {
        var deleteIndex;
        profileList.map((profile, index) => {
            if (profile.id === this.props.profile.id) {
                deleteIndex = index
            }
        })
        profileList.splice(deleteIndex, 1)
        setProfileUpdateSurveyPage(true)
    }

    deleteProfile = async () => {

        var deleteInfo = {}
        deleteInfo['id'] = this.props.profile.id
        deleteInfo['token'] = localStorage.getItem('userToken')


        const response = await fetch(deleteLink, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json'
            },
            body: JSON.stringify(deleteInfo)
        });

        const data = await response.json();
        const status = data.status;

        if (status !== "false") {
            this.setState({
                showToast: true,
                ToastMsg: 'Successfully delete the profile'
            })
            this.deleteLocalProfile()

            if (selectedProfile !== null) {
                if (this.props.profile.id === parseInt(selectedProfile.id)) {
                    setSelectedProfile(profileList[0])
                }
            }

            setTimeout(() => this.props.closeFunction(this.props.tabName), 3000)
        } else {
            this.setState({
                ToastMsg: data.message,
                showToast: true
            })

        }
    }
    render() {

        return (

            <IonContent>

                <IonToast
                    isOpen={this.state.showToast}
                    onDidDismiss={() => this.setState({ showToast: false })}
                    message={this.state.ToastMsg}
                    duration={500}
                />

                <IonAlert
                    isOpen={this.state.deleteWarning}
                    onDidDismiss={() => this.setState({ deleteWarning: false })}
                    message="Are you sure to delete the profile?"
                    buttons={[
                        {
                            text: 'Cancel',
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: blah => {
                                this.setState({ deleteWarning: false })
                            }
                        },
                        {
                            text: 'Yes',
                            handler: () => {
                                this.setState({ deleteWarning: false })
                                this.deleteProfile()
                            }
                        }
                    ]}
                />
                <BackButton
                    function={this.props.closeFunction}
                    arg={this.props.tabName}
                    title={''}
                >
                </BackButton>


                <IonCard className="profileDetailCard">
                    <IonToolbar style={{
                        textAlign: 'center'
                    }}>
                        <IonChip
                            className='accountAva'
                            style={{
                                background: this.props.profile.color
                            }}><IonLabel id='userIniAccount'>
                                {this.state.initial}
                            </IonLabel></IonChip>
                    </IonToolbar>
                    <IonToolbar>
                        <IonInput
                            type='text'
                            id='name'
                            value={this.state.name}
                            className='userNameFiled'
                            required={true}
                            onIonChange={(e) => getDetail(e, this)} >
                        </IonInput>
                        <IonIcon slot='end' icon={pencilOutline}></IonIcon>
                    </IonToolbar>
                    <IonLabel className='settingLabel' >Age</IonLabel>
                    <IonToolbar>
                        <IonInput
                            className='AccountSettingInput'
                            id='age'
                            value={this.state.age}
                            onIonChange={(e) => getDetail(e, this)} />
                        <IonIcon slot='end' icon={pencilOutline}></IonIcon>
                    </IonToolbar>

                    <IonLabel className='settingLabel' >Sex</IonLabel>
                    <IonToolbar>
                        <IonInput
                            className='AccountSettingInput'
                            id='sex'
                            value={this.state.sex}
                            onIonChange={(e) => getDetail(e, this)} />
                        <IonIcon slot='end' icon={pencilOutline}></IonIcon>
                    </IonToolbar>
                </IonCard>
                <IonCard id='accountSettingButtonCard'>
                    <IonButton onClick={this.modifyProfile}><IonIcon icon={checkmarkOutline} >
                    </IonIcon>Apply</IonButton>
                    <IonButton color='danger'
                        onClick={() => this.setState({ deleteWarning: true })}>
                        <IonIcon icon={closeOutline}></IonIcon>Delete</IonButton>


                </IonCard>

            </IonContent>
        )
    }
}

export default ProfileDetail;