
import { FCM } from '@capacitor-community/fcm';
import {
  Plugins,
  PushNotification,
  PushNotificationActionPerformed,
} from '@capacitor/core';
import { AREA_ID } from './function';

const { PushNotifications } = Plugins;
const fcm = new FCM();
const TOPIC_LIST = ["survey", "pollen", "news", "thunderstorm"]

var notifications;
export var pushList;
const r = getRandomNum();
const NIGHT_SUFFIX = "_" + AREA_ID + "_night_" + r
const DAY_SUFFIX = "_" + AREA_ID + "_day_" + r;
export function registerPush() {
  // register for push
  pushList = getPushStatus()
  PushNotifications.register()
    .then(() => {
      
      TOPIC_LIST.forEach(topic => {
        if (pushList[topic]) subscribe(topic)
      });
    })
    .catch((err) => console.log(JSON.stringify(err)));

}
function getRandomNum() {
  var sotredNum = localStorage.getItem('randomNum');
  if (sotredNum === null || sotredNum === undefined) {
    var temp = Math.floor(Math.random() * 20).toString();
    localStorage.setItem('randomNum', temp)
    sotredNum = temp
  }
  return sotredNum;
}
export function getPushStatus() {
  var storedList = localStorage.getItem('pushList');


  if (storedList !== null && storedList !== undefined) return JSON.parse(storedList);
  else {
    var blankList = {}
    TOPIC_LIST.forEach(topic => {
      blankList[topic] = true
    })
    blankList['night'] = true
    localStorage.setItem('pushList', JSON.stringify(blankList));

    return blankList
  }

}

export function unsubscribe(unsubTopic) {
  var topicDayName = unsubTopic + DAY_SUFFIX
  var topicNightName = unsubTopic + NIGHT_SUFFIX;
  fcm
    .unsubscribeFrom({ topic: topicDayName })
    .then(() => {
      writePushList(unsubTopic, false);
    })
    .catch((err) => console.log(err));
  if (pushList.night)
    fcm.unsubscribeFrom({ topic: topicNightName }).then(() => {
    })
}

function writePushList(topic, status) {
  if (pushList.topic != status) {
    pushList[topic] = status
    localStorage.setItem('pushList', JSON.stringify(pushList))
  }
}

export function subscribe(subTopic) {
  var topicDayName = subTopic + DAY_SUFFIX;
  var topicNightName = subTopic + NIGHT_SUFFIX;
  fcm
    .subscribeTo({ topic: topicDayName })
    .then(() => {
      writePushList(subTopic, true);
    })
    .catch((err) => console.log(err));
  if (pushList.night) {
    fcm.subscribeTo({ topic: topicNightName })
  }
}

export function getToken() {
  fcm
    .getToken()
    .then((q) => { return q })
    .catch((err) => console.log(err));
}

export function deleteFCM() {
  fcm
    .deleteInstance()
    .catch((err) => console.log(err));
}

export function checkAuto() {
  fcm.isAutoInitEnabled().then((r) => {
    console.log('Auto init is ' + (r.enabled ? 'enabled' : 'disabled'));
  });
}

export function push() {

  // Show us the notification payload if the app is open on our device
  PushNotifications.addListener('pushNotificationReceived',
    (notification: PushNotification) => {
      console.log('Push received: ' + JSON.stringify(notification));
    }
  );

  // Method called when tapping on a notification
  PushNotifications.addListener('pushNotificationActionPerformed',
    (notification: PushNotificationActionPerformed) => {
      console.log('Push action performed: ' + JSON.stringify(notification));
    }
  );
}
export function subscribeMidnight() {
  TOPIC_LIST.forEach(topic => {
    if (pushList[topic]) {
      fcm.subscribeTo({ topic: topic + NIGHT_SUFFIX })
    }
  });
  writePushList("night", true);
}

export function unsubscribeMidnight() {
  TOPIC_LIST.forEach(topic => {
    if (pushList[topic]) {
      fcm.unsubscribeFrom({ topic: topic + NIGHT_SUFFIX })
    }
  });
  writePushList("night", false);
}

// develp only
export function unsubscribeAll() {
  TOPIC_LIST.forEach(topic => {
    for (var i = 0; i <= 21; i++) {
      fcm.unsubscribeFrom({ topic: topic + "_" + AREA_ID + "_night_" + i })
      fcm.unsubscribeFrom({ topic: topic + "_" + AREA_ID + "_day_" + i })
    }
  });
}