import React, { Component } from 'react';
import { IonDatetime, IonCardContent, IonCard, IonCardHeader, IonToolbar, IonContent, IonIcon, IonText } from '@ionic/react';
import { thermometerOutline, sunnyOutline } from 'ionicons/icons'
import './TodayWeather.css'
import BackButton from '../../../BackButton';

var date = new Date();
var today = date.toISOString();
interface weatherDetail {
    wheatherData: any
    returnBack: any
}


class todayWeather extends Component<weatherDetail> {
    header() {
        return (<div>
            <IonIcon id='todayWeatherIcon'
                icon={thermometerOutline} style={{ paddingLeft: "10px" }} />
            <IonText id='weatherTitle'> Today’s Weather Forecast</IonText>
        </div>)
    }
    render() {
        return (

            <IonContent id='weatherPage'>
                <BackButton
                    function={this.props.returnBack}
                    arg='weather'
                    title={this.header()}
                />


                <IonCard id='weatherContent'>
                    <IonToolbar>
                        <IonText id='weatherArea'>
                            {this.props.wheatherData.weather_area}

                        </IonText>
                        <IonDatetime id="date"
                            displayFormat="DDD, DD MMM"
                            value={today} readonly={true}
                            slot='start' />
                    </IonToolbar>

                    <p><IonIcon id='weatherIcon' icon={sunnyOutline} /></p>
                    <br></br>
                    <p>
                        <IonText className='temperture'>
                            {this.props.wheatherData.max_temperture}°</IonText>
                        <IonText className='temperture'>
                            {this.props.wheatherData.min_temperture}°</IonText>
                    </p>
                    <p>
                        <IonText className='dayAndNight'>Today</IonText>
                        <IonText className='dayAndNight'>Tonight</IonText>
                    </p>
                    <IonText id='shortDescription'>
                        {this.props.wheatherData.weather}</IonText>
                </IonCard>
                <IonCard id='weatherDetailCard'>
                    <IonCardHeader id='weatherCardTitle'>Melbourne area forecast</IonCardHeader>
                    <IonCardContent id='weatherCardContent'>
                        {this.props.wheatherData.weather_detail}
                        <br /><br />
                        <u>Some data on this site is sourced from the Bureau of Meteorology.</u>
                    </IonCardContent>
                </IonCard>
            </IonContent>
        )



    }



}
export default todayWeather;