import {
  IonPage, IonContent, IonToolbar, IonIcon, IonDatetime, IonCard,
  IonCardContent, IonModal, IonButton, IonCardHeader, IonCardTitle, IonPopover, IonFabButton, IonFab
} from '@ionic/react';
import React, { Component } from 'react';
import Header from '../components/Header';
import TodayForecast from '../components/Forecast/TodayForecast/TodayForecast';
import News from '../components/Forecast/News/News';
import ForecastForWeek from '../components/Forecast/ForecastForWeek/ForecastForWeek';
import { newspaperOutline, calendarOutline, analyticsOutline, closeCircleOutline, checkmarkOutline, documentTextOutline } from 'ionicons/icons';
import './Forecast.css'
import SignIn from '../components/Auth/SignIn'
import Register from '../components/Auth/Register'
import { appSetting, getSetting } from '../functions/function'
import { registerPush } from '../functions/notificationFunction';
import { Link } from 'react-router-dom';
import { pollenLevelData, weatherToday } from '../functions/dummyData';

class Forecast extends Component {

  constructor(props) {
    super(props);
    registerPush()
  }

  async componentDidMount() {
    if (appSetting === undefined || appSetting === null) await getSetting();
    this.getData();
    this.setPage()

  }

  showForecastForWeek = true
  showNews = true

  state = {
    enterEmail: "",
    enterPassword: "",
    showSignIn: false,
    userName: null,
    pollenData: {},
    weatherData: [],
    siteName: '',
    showRegister: false,
    showForecastForWeek: true,
    showNews: true,
  }

  setPage() {

    if (appSetting === undefined || appSetting === null) return
    this.setState({
      showForecastForWeek: appSetting.forecast_for_week,
      showNews: appSetting.news
    })

  }
  acceptDisclaimer = () => {
    this.setState({ readDisclaimer: true, showSignIn: true })
    localStorage.setItem('readDisclaimer', 'true')

  };

  getInitial = (user: String) => {
    this.setState({ userName: user });
  }

  async getData() {
    try {

      const url = "http://api-sub.pollenforecast.com.au/readapi/client/yc3YxA8sZYczsVB3BXgKHraoIUAw6P5V/?demo";
      //Test dummy API
      const weatherUrl = "http://115.146.92.210/tools/testapi1";
      //const response = await fetch(url);
      //const data = await response.json();
      const data = pollenLevelData;
      this.setState({
        siteName: data.site_data[0].site_name,
        pollenData: data.site_data[0].pollen_counts.counts[0],
      });
      //const responseWeather = await fetch(weatherUrl);
      //const weatherData = await responseWeather.json();
      const weatherData = weatherToday;
      this.setState({
        weatherData: weatherData
      })
    } catch (e) {
    }
  }

  closeRegister = () => {
    this.setState({ showRegister: false })
  }

  render() {
    var myDate = new Date().toISOString();
    var readDisclaimer = false;
    if (localStorage.getItem('readDisclaimer') === null) {
      localStorage.setItem('readDisclaimer', 'false')
    }
    if (localStorage.getItem('readDisclaimer') === 'true') {
      readDisclaimer = true;
    }

    let closeSignIn = (register: boolean) => {
      this.setState({
        showSignIn: false,
        showRegister: register
      })

    }


    return (

      <IonPage id='forecastPage'>
        {/*Modal for disclaimer */}
        <IonModal id='disclaimerModal'
          isOpen={!readDisclaimer}
          swipeToClose={false}
          keyboardClose={false}
          backdropDismiss={false}

        >
          <IonCard id='disclaimerCard' >
            <IonCardHeader>
              <IonCardTitle>Disclaimer</IonCardTitle>
              <IonCardContent><br></br>Show the disclaimer<br></br><br></br>
                <IonButton onClick={this.acceptDisclaimer}>
                  <IonIcon icon={checkmarkOutline} />
                  Accept
                  </IonButton>
                <IonButton color='danger'>
                  <IonIcon icon={closeCircleOutline} />
                  Cancel
                  </IonButton>
              </IonCardContent>
            </IonCardHeader>
          </IonCard>
        </IonModal>

        {/*Suggest for signin */}
        <IonPopover cssClass='signInPop'
          isOpen={this.state.showSignIn}
          onDidDismiss={e => this.setState({ showSignIn: false })}
        >
          <SignIn closeFunction={closeSignIn} />
        </IonPopover>

        <IonPopover cssClass='fullScreenPop' isOpen={this.state.showRegister}>
          <Register
            closeRegister={this.closeRegister}
          />
        </IonPopover>

        <Header title="Forecast" />
        <IonContent id='ForecastContent' scroll-y='true' scroll-x='true'>
          <div style={{ minHeight: '85%' }}>
            {/*Today's pollen forecast and weather forecast */}
            <IonToolbar className='SectionTitleToolbar'>
              <IonIcon
                className='SectionTitleIcon'
                icon={analyticsOutline}
                slot='start'></IonIcon>
              <b>Today's forecast</b>

              <IonDatetime id="date" slot='end'
                displayFormat="DDD, DD MMM"
                value={myDate} readonly={true} />

            </IonToolbar>
            <TodayForecast
              pollenData={this.state.pollenData}
              weatherData={this.state.weatherData}
              siteName={this.state.siteName}
            />
            {/*simple forecast for thie week */}
            {this.state.showForecastForWeek === true &&
              <div>
                <IonToolbar className='SectionTitleToolbar'>
                  <IonIcon
                    slot='start'
                    icon={calendarOutline}
                    className='SectionTitleIcon'>
                  </IonIcon>
                  <b>Forecast for the week </b>
                </IonToolbar>
                <ForecastForWeek></ForecastForWeek>
              </div>
            }


            {/*News*/}
            {this.state.showNews === true &&
              <div>
                <IonToolbar className='SectionTitleToolbar'>
                  <IonIcon
                    slot='start'
                    icon={newspaperOutline}
                    className='SectionTitleIcon'>
                  </IonIcon>
                  <b>News and events </b>
                </IonToolbar>
                <News></News>
              </div>
            }


          </div>
          {/*Copyright*/}
          <IonCard id='copyrightCard'>
            <IonCardContent id='copyright' style={{ borderRadius: 0 }}>
              © 2010-2020 The University of Melbourne. All rights reserved.
              <br /><br />University of Melbourne CRICOS Provider Code: 00116K
              <br /><br />This information is copyrighted (see disclaimer & copyright).
              Apart from any use as permitted under the Copyright Act 1968,
              no part may be reproduced by any process without prior written permission.
              This includes all pollen information and forecasts.
        </IonCardContent>
          </IonCard>
          <IonFab
            vertical="bottom" horizontal="end" slot="fixed">
            <Link to="/Surveys">
              <IonFabButton color="light">

                <IonIcon icon={documentTextOutline} />

              </IonFabButton>
            </Link>
          </IonFab>
        </IonContent>
      </IonPage>
    );
  };
}
export default Forecast;