import React from 'react';
import { IonCardHeader, IonCard, IonCardTitle, IonCardContent, IonImg, IonRow, IonCol, IonDatetime } from '@ionic/react';
import './News.css';
import { newsFeed } from '../../../functions/dummyData';

const domain = 'https://www.melbournepollen.com.au'


class news extends React.Component {

    state = {
        news: [
            {
                title: "Farewell from Melbourne Pollen",
                date: "2019-12-31",
                image: "/images/EVzO-KBAApSKNk3JUXDaGHcBtu8=/3/fill-300x300/",
                path: "/news-events/farewell-melbourne-pollen"
            }

        ],
        number: 0
    }

    async componentDidMount() {
        // Need to replace before finished

        const url = "https://www.melbournepollen.com.au/newsfeed/1/";
        //const response = await fetch(url);
        //const data = await response.json();
        var data = newsFeed;
        this.setState({
            news: data.news,
            number: data.number
        });

    }

    render() {
        return (

            <IonRow id='news_row' >

                {this.state.news.map((news: any, index: any) => {
                    return (
                        <IonCol className='newsCol' key={index} sizeMd='5' sizeLg='6'>

                            <IonCard className='newsCard' href={domain + news.path} >

                                <IonCardHeader className='newsHeader'>
                                    <IonDatetime
                                        className='newsDate'
                                        displayFormat="MMM DD,YYYY"
                                        value={news.date}
                                        readonly={true} />
                                    <IonCardTitle className='newsCardTitle'>{news.title}</IonCardTitle>

                                </IonCardHeader>
                                <IonCardContent className='newsCardContent'>
                                    <IonImg
                                        src={domain + news.image}
                                        className='newsImg'
                                    />
                                </IonCardContent>
                            </IonCard>

                        </IonCol>


                    );
                })}

            </IonRow>




        )
    }
}

export default news;
