import React from 'react';
import { IonRow, IonToolbar, IonIcon, IonCol, IonCardContent, IonCard, IonDatetime, IonText, IonModal } from '@ionic/react';
import { thermometerOutline, thunderstormOutline, flowerOutline } from 'ionicons/icons';
import './ForecastForWeek.css';
import DetailedForecast from './DetailedForecast/DetailedForecast'
import 'c3/c3.css';
import c3 from "c3";
import { weatherThisWeek } from '../../../functions/dummyData';
import { chooseColor, getLevelNumber } from '../../../functions/function';
class forecastForWeek extends React.Component {

    componentDidMount() {
        this.getWeeklyData()
        this.renderChart()
    }


    componentDidUpdate() {
        this.renderChart()
    }

    state = {
        site_name: "Timbuktu",
        site_id: 27,
        district: "West and South Gippsland",
        forecasts: [],
        modalList:[false],
    }

    async getWeeklyData() {

        var data = weatherThisWeek
        this.setState({
            site_name: data.site_name,
            site_id: data.site_id,
            forecasts: data.forecast,
            district: data.district,
            modalList:new Array(data.forecast.length).fill(false)
        });


    }


    renderChart() {
        this.state.forecasts.map((fc: any, index: any) => {
            var chartID = "#pollenChart" + index
            c3.generate({
                bindto: chartID,
                data: {

                    columns: [
                        ['Grass Pollen Count', getLevelNumber(fc.pollen_level)]
                    ],
                    type: 'gauge',

                },
                gauge: {
                    label: {
                        format: function (value, ratio) {
                            return '';
                        },
                        show: false
                    },
                    min: 0,
                    max: 150,
                    units: ' grains/m3',
                },
                legend: {
                    hide: true,
                },
                tooltip: {
                    show: false
                },
                color: {
                    pattern: ['#60B044', '#F6C600', '#F97600', '#FF0000'],
                    threshold: {
                        values: [20, 50, 100, 101]
                    }
                },
                transition: {
                    duration: null
                },
                size: {
                    height: 80,
                    width: 100,

                },
                interaction: {
                    enabled: false
                },
                padding: {
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                },


            });

        })
    }

    closeDailyDetail = (index)=> {
        var modalList = this.state.modalList;
        modalList[index] = false;
    
        this.setState({modalList:modalList})
    }

    openDailyDetail = (index)=> {
      
        var modalList = this.state.modalList;
        modalList[index] = true;
        this.setState({modalList:modalList})
    }

    render() {

        return (

            <IonRow id='forecast_row' >

                {this.state.forecasts.map((fc: any, index: any) => {
                    
                    return (
                        <IonModal 
                            key = {index}
                            isOpen={this.state.modalList[index]}
                           
                            cssClass='surveyModal'>
                            <DetailedForecast
                                district={this.state.district}
                                pollenLevel={fc.pollen_level}
                                maxTemperture = {fc.max_temperture}
                                minTemperture = {fc.min_temperture}
                                airQuilty = {fc.air_quality}
                                thunderstormAsthmaRisk = {fc.thunderstorm_asthma_risk}
                                date = {fc.forecast_date}
                                closeFunc = {this.closeDailyDetail}
                                closeArgs = {index}
                                />
                        </IonModal>
                    )
                })}
                {this.state.forecasts.map((fc: any, index: any) => {
                    var chartID = "pollenChart" + index
                    return (

                        <IonCol className='forecastCol' key={index}>


                            <IonCard className='forecastCard'
                            onClick = {()=>this.openDailyDetail(index)}>
                                <IonDatetime
                                    className='forecastDate'
                                    displayFormat="DDD, DD"
                                    value={fc.forecast_date}
                                    readonly={true} />

                                <IonCardContent className='forecastCardContent' >

                                    <div id={chartID}></div>

                                    <IonText
                                        style={{
                                            color: chooseColor(fc.pollen_level),
                                        }}
                                        id='level'>{fc.pollen_level}</IonText>


                                    <IonToolbar className='forecastInformation'>
                                        <IonIcon icon={thermometerOutline} slot='start'></IonIcon>
                                        {fc.min_temperture}° ~  {fc.max_temperture}°
                                            </IonToolbar>


                                    <IonToolbar className='forecastInformation'>
                                        <IonIcon icon={flowerOutline} slot='start'></IonIcon>
                                        <IonText
                                            style={{
                                                color: chooseColor(fc.air_quality),
                                                margin: 0,
                                                padding: 0,
                                            }}>{fc.air_quality}</IonText>

                                    </IonToolbar>

                                    <IonToolbar className='forecastInformation'>
                                        <IonIcon icon={thunderstormOutline} slot='start'></IonIcon>
                                        <IonText
                                            style={{
                                                color: chooseColor(fc.thunderstorm_asthma_risk),
                                                margin: 0,
                                                padding: 0,
                                            }}>{fc.thunderstorm_asthma_risk}</IonText>
                                    </IonToolbar>


                                </IonCardContent>


                            </IonCard>

                        </IonCol>


                    );
                })
                }


            </IonRow >

        )
    }

}

export default forecastForWeek;

