import { IonButtons, IonMenuButton, IonTitle, IonToolbar, IonImg, IonText } from '@ionic/react';
import React from 'react';
import './Header.css'

const pollenIcon = "/assets/icon/Logo.png"


interface headerTitle {
  title: String;
}

class header extends React.Component<headerTitle> {

  state = {
    color: "tertiary",
  }



  render() {
    return (
      <IonToolbar color='#1c3451' id="header_all_toobar" >
        <IonTitle id="header_all"> <IonImg src={pollenIcon} id='headerIcon' />
          <IonText id='headerText'>{this.props.title}</IonText></IonTitle>
        <IonButtons slot="end">
          <IonMenuButton id='headerMenuButton' />
        </IonButtons >
      </IonToolbar>

    )
  }
};

export default header;