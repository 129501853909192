import { IonContent, IonPage, IonImg, IonCard, IonCardHeader, IonCardContent, IonGrid, IonRow, IonCol, IonFooter } from '@ionic/react';
import React from 'react';
import Header from '../components/Header'
import { appSetting, getSetting } from '../functions/function';
import './AboutThisApp.css';

const logo = '/assets/img/uomlogo.png'
const WSU = '/assets/img/Western Sydey University.png'
const NSWHealth = '/assets/img/NSW_Health.png'

class AboutThisApp extends React.Component {

  async componentDidMount() {
    if (appSetting === undefined) await getSetting()
    if (appSetting !== undefined && appSetting.about_the_app !== null) {
      this.setState({
        custContent: appSetting.about_the_app,
        paragraphs: appSetting.about_the_app.split('\r\n'),
        img1: appSetting.about_the_app_img_1,
        img2: appSetting.about_the_app_img_2,
        img3: appSetting.about_the_app_img_3,
        custormize: true
      });


    }
  }

  state = {
    custormize: false,
    custContent: '',
    img1: '',
    img2: '',
    img3: '',
    paragraphs: ['']
  }


  render() {

    return (

      <IonPage>
        <Header title="About This App" />
        <IonContent >
          <div style={{minHeight:'87%'}}>
            {!this.state.custormize &&
              <IonGrid style={{ border: 0, margin: 0, padding: 0 }}>
                <IonRow style={{ backgoundColor: '#014085', textAlign: 'center' }}>
                  <IonCol col-4> </IonCol>
                  <IonCol col-4>
                    <IonImg style={{ height: '200px', width: '300px' }} src={logo} />
                  </IonCol>
                  <IonCol col-4> </IonCol>
                </IonRow>
                <IonRow style={{ borderBottomStyle: 'groove' }} >
                  <IonCol>
                    <IonCard style={{ color: 'blank', boxShadow: 'none' }}>
                      <IonCardHeader style={{ color: 'blank', fontWeight: 'bold', fontSize: 'large' }}>Who made this app?</IonCardHeader>
                      <IonCardContent>
                        This app is the property of the University of Melbourne.
                  Pollen count data are provided by<b><u> Western Sydney University</u></b>,
                  and <b><u>the Bureau of Meteorology</u></b>.
                </IonCardContent>
                    </IonCard>
                  </IonCol>
                </IonRow >
                <IonRow row-6>
                  <IonCol>
                    <IonCard style={{ textAlign: 'center', boxShadow: 'none', color: 'black' }}>
                      <IonCardContent>
                        <p>Made possible with</p>
                        <p> the support of</p>
                        <IonImg className='cooperatorImg' src={WSU} />
                        <IonImg className='cooperatorImg' src={NSWHealth} />
                      </IonCardContent>
                    </IonCard>
                  </IonCol>
                </IonRow >

              </IonGrid>
            }
            {this.state.custormize &&
              <div style={{ margin: '20px',paddingBottom:'30px' }}>

                <div className='settingText'>
                  {this.state.paragraphs.map((p, index) => {
                    return (
                      <div key={index} style={{marginBottom:'15px'}}>
                        {p}
                      </div>
                    )
                  })}
                </div>
                <div style={{ textAlign: 'center' }}>
                  {this.state.img1 !== null &&
                    <IonImg class='cooperatorImg' src={this.state.img1} />
                  }
                  {this.state.img2 !== null &&
                    <IonImg class='cooperatorImg' src={this.state.img2} />
                  }
                  {this.state.img3 !== null &&
                    <IonImg class='cooperatorImg' src={this.state.img3} />
                  }
                </div>

              </div>
            }
          </div>
          <IonCard id='copyrightCard'>
            <IonCardContent id='copyright'>
              © 2010-2020 The University of Melbourne. All rights reserved.
                  <br /><br />University of Melbourne CRICOS Provider Code: 00116K
                  <br /><br />This information is copyrighted (see disclaimer & copyright).
                  Apart from any use as permitted under the Copyright Act 1968,
                  no part may be reproduced by any process without prior written permission.
                  This includes all pollen information and forecasts.
        </IonCardContent>
          </IonCard>
        </IonContent>

      </IonPage>

    );
  }
};

export default AboutThisApp;