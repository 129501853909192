import React, { Component } from 'react';
import { IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonLabel, IonInput, IonButton, IonToolbar, IonRouterLink, IonIcon, IonPopover, IonToast } from '@ionic/react';
import './SignIn.css'
import { closeOutline } from 'ionicons/icons';
import {  getDetail, setLocalUserInfo } from '../../functions/function';


const signInPage = "https://portal.dev.pollenforecast.com.au/mobile-app/auth/login"
//const signInPage = 'http://127.0.0.1:8000/mobile-app/auth/login'
interface signInInterface {
    closeFunction: any,

}

class signIn extends Component<signInInterface> {
    state = {
        enterEmail: "",
        enterPassword: "",
        alert: false,
        successful: false,
        status: "",
        returnMessage: "",
        userName: "",
        age: "",
        color: '',

    }

    handleSubmit = async (e) => {
        e.preventDefault();

        const response = await fetch(signInPage, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
            },
            body: JSON.stringify({
                email: this.state.enterEmail,
                password: this.state.enterPassword,
            }),
        });
        const data = await response.json();
        if (data.status === "success") {
            this.setState({ successful: true })
            this.state.userName = data.profiles[0].name
            localStorage.setItem("userToken", data.token)
            setLocalUserInfo(data)
        } else {
            this.setState({ alert: true });
        }
        this.setState({ returnMessage: data.message })

    }


    render() {

        return (
            <IonCard id='signInCard' >
                <IonPopover
                    id='successfulPopover'
                    isOpen={this.state.alert}
                    onDidDismiss={() => this.setState({ alert: false })}  >
                    <IonCard id='successfulCard'>
                        <IonCardHeader><IonCardTitle>Fail to sign in</IonCardTitle></IonCardHeader>
                        <IonCardContent id='signInmessage'>{this.state.returnMessage}<br></br><br></br>
                            <IonButton id='successfulReturnButton' onClick={() => this.setState({ alert: false })}> OK</IonButton>
                        </IonCardContent>
                    </IonCard>
                </IonPopover>
                <IonToast
                    isOpen={this.state.successful}
                    onDidDismiss={() => {
                        this.setState({ successful: false });
                        this.props.closeFunction(false)
                    }}
                    message="Successfully sign in"
                    duration={300}
                />

                <IonToolbar id='signInToolbar' onClick={() => this.props.closeFunction(false)}>
                    <IonIcon slot='start' icon={closeOutline} />Cancel
          </IonToolbar>
                <IonCardHeader>
                    <IonCardTitle className='ion-text-center'>
                        Sign In
        </IonCardTitle>
                </IonCardHeader>
                <IonCardContent id='enterFiled' >
                    <IonLabel >Email</IonLabel>
                    <IonInput
                        type='email'
                        id='enterEmail'
                        pattern='email'
                        required={true}
                        placeholder='abc@123.com'
                        onIonChange={(e) => getDetail(e, this)} />

                    <IonLabel>Password</IonLabel>
                    <IonInput
                        type='password'
                        id='enterPassword'
                        pattern='password'
                        required={true}
                        placeholder='1234567'
                        onIonChange={(e) => getDetail(e, this)} />
                    <br></br>
                If you don't have an account, please
                <IonRouterLink onClick={() => this.props.closeFunction(true)} color='primary' ><u> sign up here</u></IonRouterLink>.
                If you want to sign in later, click cancel.
                </IonCardContent>
                <IonToolbar className='ion-text-center'>
                    <IonButton type='submit' onClick={this.handleSubmit} >Login</IonButton>
                </IonToolbar>

            </IonCard>
        )


    }
}

export default signIn;