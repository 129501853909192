import React, { Component } from 'react';
import BackButton from '..//..//../BackButton';
import {  IonContent, IonDatetime, IonToolbar, IonIcon, IonText, IonRow } from '@ionic/react';
import { calendarOutline, flowerOutline, thermometerOutline, sunnyOutline, thunderstormOutline } from 'ionicons/icons';
import c3 from "c3";
import 'c3/c3.css';
import "./DetailedForecast.css"
import { chooseColor, getLevelNumber } from '../../../../functions/function';

var date = new Date();
var today = date.toISOString();

interface ForecastDetailedProp {
    district: string;
    pollenLevel: string;
    maxTemperture: string;
    minTemperture: string;
    airQuilty: string;
    thunderstormAsthmaRisk: string;
    date: any;
    closeFunc: any,
    closeArgs: any,

}

class detailedForecast extends Component<ForecastDetailedProp> {
    renderChart() {

        var chartID = "#pollenChartToday";
        c3.generate({
            bindto: chartID,
            data: {
                columns: [
                    ['Grass Pollen Count', getLevelNumber(this.props.pollenLevel)]
                ],
                type: 'gauge',
            },
            gauge: {
                label: {
                    format: function (value, ratio) {
                        return '';
                    },
                    show: false
                },
                min: 0,
                max: 150,
                units: ' grains/m3',
            },
            legend: {
                hide: true,
            },
            tooltip: {
                show: false
            },
            color: {
                pattern: ['#60B044', '#F6C600', '#F97600', '#FF0000'],
                threshold: {
                    values: [20, 50, 100, 101]
                }
            },
            size: {
                height: 90
            }
        });


    }

    header() {
        return (
            <div>
                <IonIcon slot='start' font-weight='bold' icon={calendarOutline} />
                <IonDatetime id="forecastModalDate"
                    displayFormat="DDDD, DD MMM"
                    value={this.props.date} readonly={true} ></IonDatetime>

            </div>
        )
    }
    render() {

        return (

     
                <IonContent id='forecastModal'>
                    <BackButton
                        function={this.props.closeFunc}
                        arg={this.props.closeArgs}
                        title={this.header()}
                    />

                        <IonRow className='forecastModalRow'>
                            <IonText className='modalDistrictText'>Pollen forecast in your district:</IonText>
                            <p><IonText className='modalAirQualitySite'>{this.props.district}</IonText></p>
                            <div id="pollenChartToday"></div>
                            <IonText
                                id='modalLevelText'
                                style={{
                                    color: chooseColor(this.props.pollenLevel),
                                    margin: 0,
                                    padding: 0,
                                }}>

                                <b>{this.props.pollenLevel}</b>
                            </IonText>
                        </IonRow>
                        <IonRow className='forecastModalRow'>
                            <IonToolbar >
                                <IonIcon font-weight='bold' slot='start' icon={thermometerOutline} />
                                <IonText id='weatherTitle'>Weather</IonText>
                            </IonToolbar>
                            <p><IonIcon id='modalWeatherIcon' icon={sunnyOutline} /></p>
                            <p><IonText className='modalTempertureDetail'>{this.props.minTemperture}°<br></br>Min</IonText></p>
                            <p><IonText className='modalTempertureDetail'>{this.props.maxTemperture}°<br></br>Max</IonText>
                            </p>
                            <IonText></IonText>
                        </IonRow>
                        <IonRow className='forecastModalRow'>
                            <IonToolbar>
                                <IonIcon font-weight='bold' slot='start' icon={flowerOutline} />
                                <IonText id='weatherTitle'>Air quality</IonText>
                                <IonText
                                    slot='end'
                                    style={{
                                        color: chooseColor(this.props.airQuilty),
                                    }}>
                                    {this.props.airQuilty}</IonText>
                            </IonToolbar >
                            <IonText className='modalDistrictText'>In your district</IonText>
                            <p><IonText className='modalAirQualitySite'>{this.props.district}</IonText></p>

                        </IonRow>
                        <IonRow>
                            <IonToolbar>
                                <IonIcon font-weight='bold' slot='start' icon={thunderstormOutline} />
                                <IonText id='weatherTitle'>Thunderstorm asthma risk</IonText>
                                <IonText
                                    slot='end'
                                    style={{
                                        color: chooseColor(this.props.thunderstormAsthmaRisk),
                                    }}>
                                    {this.props.thunderstormAsthmaRisk}</IonText>
                            </IonToolbar >
                            <IonText className='modalDistrictText'>In your district</IonText>
                            <p><IonText className='modalAirQualitySite'>{this.props.district}</IonText></p>


                        </IonRow>

                </IonContent>
        
        )
    }


}
export default detailedForecast;