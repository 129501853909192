import { Component } from "react";
import React from 'react';
import { IonCard, IonToolbar, IonIcon, IonChip, IonLabel, IonCardContent, IonInput, IonButton, IonToast, IonAlert } from "@ionic/react";
import { personOutline, pencilOutline, closeOutline, checkmarkOutline } from "ionicons/icons";
import { getDetail, logOut, userEmail } from '../../functions/function'
interface accountDetailIntreface {
    email: string,
    initial: string,
    color: any,
    class: any,
}
//const changeInfoPage = 'http://127.0.0.1:8000/mobile-app/auth/change-detail'

const changeInfoPage = 'https://portal.dev.pollenforecast.com.au/mobile-app/auth/change-detail'

class AccountDetail extends Component<accountDetailIntreface>{
    state = {
        password1: '',
        password2: '',
        email: this.props.email,
        messageToast: false,
        message: '',
        initial: null,
        emailChange: false,

    }
    clearInfo() {
        this.setState({
            email: userEmail,
            password1: '',
            password2: '',
        })
    }

    precheck() {
        if (this.state.password1 === '' && this.state.password2 === '') {
            if (this.state.email === userEmail) {
                this.setState({
                    messageToast: true,
                    message: "Nothing changes",
                })
                return
            }
        }
        if (this.state.email !== userEmail) {
            this.setState({ emailChange: true })
        } else {
            this.applyChange(false)
        }
    }

    applyChange = async (changeEmail) => {
        var password1 = this.state.password1;
        var changedInfo = {};
        if (password1 !== '' && this.state.password2 !== '') {
            if (password1 !== this.state.password2) {
                this.setState({
                    messageToast: true,
                    message: 'Password do not match'
                })
                return;
            } else {
                changedInfo['password'] = password1;
            }
        }
        changedInfo['token'] = localStorage.getItem('userToken')
        changedInfo['email'] = this.state.email;

        const response = await fetch(changeInfoPage, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json'
            },
            body: JSON.stringify(changedInfo)
        });
        const data = await response.json();
        if (data.status === 'success') {
            this.setState({
                messageToast: true,
                message: "Successfully change the account detail",

            })
            if (changeEmail === false) {
                localStorage.setItem("userToken", data.token)
                this.setState({
                    password1: "",
                    password2: '',
                })
                var pass1 = document.getElementById('password1') as HTMLIonInputElement
                var pass2 = document.getElementById('password2') as HTMLIonInputElement
                if (pass1 !== null && pass2 !== null) {
                    pass1.value = ''
                    pass2.value = ''
                }
            } else {
                logOut(this.props.class)
            }

        }

    }

    render() {
        return (
            <div>
                <IonToast
                    isOpen={this.state.messageToast}
                    onDidDismiss={() => this.setState({ messageToast: false })}
                    message={this.state.message}
                    duration={500}
                />

                <IonAlert
                    isOpen={this.state.emailChange}
                    onDidDismiss={() => this.setState({ emailChange: false })}

                    header={'Will logout'}

                    message={'User will be loged out. The new email will need to be verified before next login. Are you sure to change the email?'}
                    buttons={[
                        {
                            text: 'Cancel',
                            handler: () => {
                                this.setState({ emailChange: false })
                            }
                        },
                        {
                            text: 'Ok',
                            handler: () => {
                                this.setState({ emailChange: false })
                                this.applyChange(true)

                            }
                        }]}

                ></IonAlert>
                <IonToolbar className='SectionTitleToolbar'>

                    <IonIcon
                        className='SectionTitleIcon'
                        icon={personOutline}
                        slot='start'></IonIcon>
                    <b>Account settings</b>
                </IonToolbar>
                <IonToolbar style={{
                    textAlign: 'center'
                }}>

                    <IonChip
                        className='accountAva'
                        style={{
                            background: this.props.color
                        }}

                    ><IonLabel id='userIniAccount'>{this.props.initial}</IonLabel></IonChip>

                </IonToolbar>
                <IonCardContent className='accountSettingFiled'>

                    <IonLabel className='settingLabel'>Email</IonLabel>

                    <IonToolbar>
                        <IonInput
                            className='AccountSettingInput'
                            type='email'
                            id='email'
                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                            required={true}
                            value={this.state.email}
                            onIonChange={(e) => getDetail(e, this)} />
                        <IonIcon slot='end' icon={pencilOutline}></IonIcon>
                    </IonToolbar>

                    <IonLabel className='settingLabel'>Change Password</IonLabel>

                    <IonToolbar>
                        <IonInput
                            className='AccountSettingInput'
                            type='password'
                            id='password1'
                            required={false}
                            value={this.state.password1}
                            onIonChange={(e) => getDetail(e, this)} />
                        <IonIcon slot='end' icon={pencilOutline}></IonIcon>
                    </IonToolbar>


                    <IonLabel className='settingLabel'>Repeat Password</IonLabel>

                    <IonToolbar>
                        <IonInput
                            className='AccountSettingInput'
                            type='password'
                            id='password2'
                            required={false}
                            value={this.state.password2}
                            onIonChange={(e) => getDetail(e, this)} />
                        <IonIcon slot='end' icon={pencilOutline}></IonIcon>
                    </IonToolbar>
                </IonCardContent>
                <br></br>

                <IonCard id='accountSettingButtonCard'>
                    <IonButton color='light' onClick={() => this.clearInfo()}>
                        <IonIcon icon={closeOutline}></IonIcon>Discard</IonButton>
                    <IonButton onClick={() => this.precheck()} ><IonIcon icon={checkmarkOutline} >
                    </IonIcon>Apply</IonButton>
                </IonCard>
            </div>
        )
    }

}

export default AccountDetail;