import React, { Component } from 'react'
import {
    IonCard, IonCardTitle, IonCardContent, IonLabel, IonInput, IonButton,
   IonPopover, IonCardHeader,
} from '@ionic/react'
import './Register.css'
import { getDetail } from '../../functions/function'

const registerURL = 'https://portal.dev.pollenforecast.com.au/mobile-app/auth/register'

interface registerInterface {
    closeRegister: any,
}


class register extends Component<registerInterface> {

    state = {
        email: '',
        password: '',
        username: '',
        age: '',
        successful: false,
        alert: false,
        errorMessage: "",
        sex: '',
    }


    handleSubmit = async (e) => {
        var sex = "Not specified"
        if (this.state.sex != '') {
            sex = this.state.sex
        }
        e.preventDefault();
        // Need to replace before finished


        const response = await fetch(registerURL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json'
            },
            body: JSON.stringify({
                email: this.state.email,
                password: this.state.password,
                name: this.state.username,
                age: this.state.age,
                sex: sex
            })


        });

        const data = await response.json();
        if (data.status == 'success') {
            this.setState({ successful: true, 
                            email:'',
                            password:'',
                            username:'',
                            age:'',
                            sex:'',
            })
            var email = document.getElementById('email') as HTMLIonInputElement
            var password = document.getElementById('password') as HTMLIonInputElement
            var name = document.getElementById('username') as HTMLIonInputElement
            var age = document.getElementById('age') as HTMLIonInputElement
            var sexComponent = document.getElementById('sex') as HTMLIonInputElement
            email.value=''
            password.value=''
            name.value=''
            age.value=''
            sexComponent.value=''
        } else {
            this.setState({
                alert: true,
                errorMessage: data.message
            })
        }

    }

    render() {
        return (
            <div id = 'registerPageContent'>

                {/*User fill in the blank */}
                <IonCard className="RegisterCard">
                    <form onSubmit={this.handleSubmit}>
                        <IonCardTitle id='registerTitle'>Welcome!</IonCardTitle>
                        <IonCardContent className='registerInputField'>

                            <IonLabel className='settingLabel' >Email</IonLabel>
                            <IonInput
                                className='registerInput'
                                type='email'
                                id='email'
                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                                required={true}
                                placeholder='abc@123.com'
                                onIonChange={(e) => getDetail(e,this)} />

                            <IonLabel className='settingLabel' >Password</IonLabel>
                            <IonInput
                                className='registerInput'
                                type='password'
                                id='password'
                                required={true}
                                onIonChange={(e) => getDetail(e,this)} />


                            <IonLabel className='settingLabel'>Name</IonLabel>
                            <IonInput
                                className='registerInput'
                                type='text'
                                id='username'
                                required={true}
                                onIonChange={(e) => getDetail(e,this)} />

                            <IonLabel className='settingLabel'>Age</IonLabel>
                            <IonInput
                                className='registerInput'
                                type='number'
                                id='age'
                                required={true}
                                onIonChange={(e) => getDetail(e,this)} />
                            <IonLabel className='settingLabel'>Sex(optional)</IonLabel>
                            <IonInput
                                className='registerInput'
                                type='text'
                                id='sex'
                                onIonChange={(e) => getDetail(e,this)} />
                        </IonCardContent>
                        <br></br>
                        <IonButton type='submit'>Register</IonButton>
                        <IonButton color="light"
                         onClick={() => this.props.closeRegister()}>Cancel</IonButton>
                      
                    </form>
                </IonCard>


                <IonPopover id='successfulPopover'
                    isOpen={this.state.successful}
                    onDidDismiss={() => this.setState({ successful: false })}  >
                    <IonCard id='successfulCard'>
                        <IonCardHeader><IonCardTitle>Successful</IonCardTitle></IonCardHeader>
                        <IonCardContent id='signInmessage'>
                             please vertify the email in before sign in<br></br><br></br>
                            <IonButton      
                                onClick={() => {
                                    this.setState({ successful: false });
                                    this.props.closeRegister()

                                }}
                                id='successfulReturnButton'>
                                OK</IonButton>
                        </IonCardContent>
                    </IonCard>
                </IonPopover>

                <IonPopover id='successfulPopover'
                    isOpen={this.state.alert}
                    onDidDismiss={() => this.setState({ alert: false })}  >
                    <IonCard id='successfulCard'>
                        <IonCardHeader><IonCardTitle>Fail to register</IonCardTitle></IonCardHeader>
                        <IonCardContent id='signInmessage'>{this.state.errorMessage}<br></br><br></br>
                            <IonButton id='successfulReturnButton' onClick={() => this.setState({ alert: false })}> OK</IonButton>
                        </IonCardContent>
                    </IonCard>
                </IonPopover>
            </div>
        )


    }



}
export default register;