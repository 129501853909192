import React, { Component } from 'react';
import { IonContent, IonDatetime, IonToolbar, IonIcon, IonText, IonCard, IonCardHeader, IonCardContent, IonImg, IonButtons } from '@ionic/react';

import { chevronBackSharp, flowerOutline } from 'ionicons/icons';
import './AirQuality.css'
import { chooseColor } from '../../../../functions/function';
import BackButton from '../../../BackButton';
var date = new Date();
var today = date.toISOString();
const picture = "/assets/img/AirQulityPicture(PlaceHolder).png"

interface weatherDetail {
    wheatherData: any
    returnBack: any
}


class airQuality extends Component<weatherDetail> {
    header() {
        return (<div>
            <IonIcon id='todayWeatherIcon' font-weight='bold' icon={flowerOutline} />
            <IonText id='weatherTitle'>Today’s air quality forecast</IonText>
        </div>)
    }

    render() {

        return (


            <IonContent id='weatherPage'>
    
                    <BackButton
                        function={this.props.returnBack}
                        arg='air'
                        title={this.header()}
                    />

    
                <IonCard id='weatherContent'>

                    <IonDatetime id="date"
                        displayFormat="DDD, DD MMM"
                        value={today} readonly={true} />


                    <p><IonText className='districtText'>In your district</IonText></p>
                    <p><IonText className='airQualitySite'>{this.props.wheatherData.district}</IonText></p>
                    <p><IonText className='airQualityLevel'
                        style={{
                            color: chooseColor(this.props.wheatherData.air_quality_forecast),
                        }}>
                        {this.props.wheatherData.air_quality_forecast}
                    </IonText></p>
                    <br></br>
                    <br></br>
                    <IonToolbar >
                        <IonIcon id='todayWeatherIcon' font-weight='bold' slot='start' icon={flowerOutline} />
                        <IonText id='weatherTitle'>Current air quality</IonText>
                    </IonToolbar>
                    <p><IonText className='districtText'>Nearest monitoring site</IonText></p>
                    <p><IonText className='airQualitySite'>{this.props.wheatherData.air_quality_site}</IonText></p>
                    <p><IonText className='airQualityLevel'
                        style={{
                            color: chooseColor(this.props.wheatherData.current_air_quality),
                        }}>
                        {this.props.wheatherData.current_air_quality}
                    </IonText></p>
                    <IonText id='shortDescription'>
                        Based on PM2.5<br></br>
                        Air quality at this site is {this.props.wheatherData.current_air_quality}
                    </IonText>
                </IonCard>'
                <IonCard id='weatherDetailCard'>
                    <IonCardHeader id='weatherCardTitle'>Air quality in the last 48 hours</IonCardHeader>
                    <IonCardContent id='weatherCardContent'>
                        A general overview of air quality over the last 48 hours,
                        calculated at one-hour averages. For more information about
                        pollutant concentrations, see the pollutant concentrations
                        graph below.
                            <br /><br />
                        <IonImg src={picture} ></IonImg>
                        <u>About EPA AirWatch.</u>
                    </IonCardContent>
                </IonCard>

            </IonContent>


        )
    }



}
export default airQuality;