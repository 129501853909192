import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,

} from '@ionic/react';
import React from 'react';
import { RouteComponentProps, withRouter} from 'react-router-dom';
import { closeOutline,homeOutline,homeSharp,addCircleOutline,addCircleSharp ,helpCircleOutline,helpCircleSharp,personCircleOutline,personCircleSharp,
  alertCircleOutline, alertCircleSharp,libraryOutline,librarySharp,chatbubblesOutline,chatbubblesSharp} from 'ionicons/icons';
import './Menu.css';

interface MenuProps extends RouteComponentProps {
  selectedPage: string;
}

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const appPages: AppPage[] = [
  {
    title: 'Forecast',
    url: '/Forecast', 
    iosIcon: homeOutline,
    mdIcon: homeSharp
  },
  {
    title: 'Surveys',
    url: '/Surveys',
    iosIcon: addCircleOutline,
    mdIcon: addCircleSharp
  },

  {
    title: 'Account Setting',
    url: '/AccountSetting',
    iosIcon: personCircleOutline,
    mdIcon: personCircleSharp
  },
  {
    title: 'Notification Setting',
    url: '/NotificationSetting',
    iosIcon: alertCircleOutline,
    mdIcon: alertCircleSharp
  },
  {
    title: 'Help',
    url: '/Help',
    iosIcon: helpCircleOutline,
    mdIcon: helpCircleSharp
  },
  {
    title: 'About This App',
    url: '/AboutThisApp',
    iosIcon: libraryOutline,
    mdIcon: librarySharp
  },
  {
    title: 'Connect With Us',
    url: '/ConnectWithUs',
    iosIcon: chatbubblesOutline,
    mdIcon: chatbubblesSharp
  },
];


const Menu: React.FunctionComponent<MenuProps> = ({ selectedPage }) => {
  return (
    <IonMenu contentId="main" type="overlay" side = "end" >
      <IonContent>
        <IonList id="menu-list">
          <IonMenuToggle>
           <IonItem><IonIcon icon={closeOutline} slot="start"></IonIcon>
          <IonLabel >Close</IonLabel>
          </IonItem>
          </IonMenuToggle>
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle   key={index} autoHide={false}>
                <IonItem id = "menu-item" className={selectedPage === appPage.title ? 'selected' : ''} 
                routerLink={appPage.url} routerDirection="none"  lines="none" detail={false}>
                  <IonIcon slot="start" icon={appPage.iosIcon} />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>

      </IonContent>
    </IonMenu>
  );
};

export default withRouter(Menu);
