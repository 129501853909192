import { IonContent, IonPage, IonText, IonCard, IonPopover, IonLabel, IonIcon, IonToolbar, IonChip, IonToast, IonModal, IonItem, IonList, } from '@ionic/react';
import React from 'react';
import Header from '../components/Header'
import './AccountSetting.css'
import SignIn from '../components/Auth/SignIn'
import { personAddOutline, menuOutline, } from 'ionicons/icons';
import AccountDetail from '../components/AccountSetting/AccountDetail'
import ProfileDetail from '../components/AccountSetting/ProfileDetail'
import Register from '../components/Auth/Register'
import AddProfile from '../components/AccountSetting/AddProfile'
import { getInitial, isLogined, LogInByToken, logOut, profileList, profileUpdatedAccountPage, setProfileUpdatedAccountPage, setProfileUpdateSurveyPage, userEmail, } from '../functions/function'


//const signInPage = "http://127.0.0.1:8000/mobile-app/auth/login"
//const changeInfoPage = 'http://127.0.0.1:8000/mobile-app/auth/change-detail'



class AccountSetting extends React.Component {

  constructor(props) {
    super(props)
  }
  state = {
    email: '',
    accountColor: null,
    accountIni: '',
    login: false,
    showSignIn: false,
    profiles: [],
    loginFail: false,
    failMessage: '',
    addNew: false,

  }

  closeProfileDetail = (name) => {
    this.setState({
      [name]: false,
    })
    this.setProfile()

  }

  closeSignIn = () => {
    this.setState({
      showSignIn: false,
    })
    this.setProfile()
  }

  closeAddNew = () => {
    this.setState({
      addNew: false,
    })
    this.setProfile()
    setProfileUpdateSurveyPage(true)
  }

  setProfile() {

    if (profileList !== null) {
      this.setState({
        login: true,
        email: userEmail,
        profiles: profileList,
        accountColor: profileList[0].color,
        accountIni: getInitial(profileList[0].name)
      })
    }
  }

  async componentDidMount() {
    if (!isLogined) await LogInByToken()
    if (isLogined) this.setProfile()
  }

  async componentDidUpdate() {
    if (profileUpdatedAccountPage) {
      setProfileUpdatedAccountPage(false)
      this.setProfile()
    }
  }
  render() {
    return (
      <IonPage >
        <Header title="Account Setting" />
        <IonContent>

          {/*Suggest for signin */}
          <IonPopover cssClass='signInPop'
            isOpen={this.state.showSignIn}
            onDidDismiss={e => this.setState({ showSignIn: false })}
          >
            <SignIn closeFunction={this.closeSignIn} />
          </IonPopover>

          <IonModal
            isOpen={this.state.addNew}
            onDidDismiss={e => this.setState({ addNew: false })}
          >
            <AddProfile
              closeFunction={this.closeAddNew}
            />
          </IonModal>

          <IonToast
            isOpen={this.state.loginFail}
            onDidDismiss={() => this.setState({ loginFail: false })}
            message={this.state.failMessage}
            duration={500}
          />

          {this.state.profiles.map((profile: any, index: any) => {
            var profileOpen = "profile" + index

            return (
              <IonModal key={index}

                isOpen={this.state[profileOpen]}
                onDidDismiss={() => this.setState({ [profileOpen]: false })}
              >
                <ProfileDetail
                  closeFunction={this.closeProfileDetail}
                  tabName={profileOpen}
                  profile={profile}
                />
              </IonModal>
            )
          })
          }

          {this.state.login === true &&
            <IonCard className="accountSettingCard">
              <AccountDetail
                email={this.state.email}
                initial={this.state.accountIni}
                color={this.state.accountColor}
                class={this}
              ></AccountDetail>


              <IonToolbar className='SectionTitleToolbar'>
                <IonIcon
                  className='SectionTitleIcon'
                  icon={personAddOutline}
                  slot='start'></IonIcon>
                <b>Additional profiles</b>
              </IonToolbar>
              <IonCard id='addProfileCard'>
                <IonText id='subText'>
                  Manage additional profile information, or create a new profile</IonText>
                <br></br>  <br></br>
                {this.state.profiles.map((profile: any, index: any) => {
                  var profileOpen = "profile" + index

                  return (
                    <IonChip id='additionalProfileChips' key={index}
                      onClick={() => this.setState({ [profileOpen]: true })}
                      style={{ backgroundColor: profile.color }}
                    >
                      <IonLabel class='additionalProfileLabel'>
                        {getInitial(profile.name)}
                      </IonLabel></IonChip>
                  )
                })
                }
                <IonChip id='additionalProfileChips' style={{ backgroundColor: 'cornflowerblue' }}
                  onClick={() => this.setState({ addNew: true })}>
                  <IonLabel class='additionalProfileLabel' >+
                   </IonLabel></IonChip>

              </IonCard>

              <IonToolbar className='SectionTitleToolbar'>
                <IonIcon
                  className='SectionTitleIcon'
                  icon={menuOutline}
                  slot='start'></IonIcon>
                <b>Account data</b>
              </IonToolbar>
              <IonList lines="full"
                style={{
                  paddingTop: '0',
                  paddingLeft: '25px',
                  paddingRight: '25px'
                }}>
                <IonItem button color="light" onClick={() => logOut(this)}>
                  <IonLabel>
                    Log out
                </IonLabel>

                </IonItem>
              </IonList>
            </IonCard>
          }
          {this.state.login !== true &&
            <div>
              <Register
                closeRegister={() => null}
              />
              <br></br>
              <p style={{ margin: '20px', color: 'grey', textAlign: 'center' }}>
                or <u id='surveySignIn'
                  onClick={() => this.setState({ showSignIn: true })}>sign in</u> </p>
            </div>
          }

        </IonContent>
      </IonPage>
    );
  };
}

export default AccountSetting;