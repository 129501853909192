import { IonContent, IonToast, IonToolbar, IonButtons, IonIcon, IonCard, IonLabel, IonInput, IonButton } from '@ionic/react'
import { chevronBackSharp, pencilOutline, checkmarkOutline, personAddOutline } from 'ionicons/icons'
import React, { Component } from 'react'
import {getDetail, getRandomColor, profileList} from '../../functions/function'
//const addLink = 'http://127.0.0.1:8000/mobile-app/auth/add-profile'
const addLink = 'https://portal.dev.pollenforecast.com.au/mobile-app/auth/add-profile'
interface AddProfileInterface {
    closeFunction: any
}

class AddProfile extends Component<AddProfileInterface>{
    state = {
        createSuccess: false,
        createFail: false,
        failMessage: '',
        name: '',
        age: '',
        sex: '',
    }

    addProfile = async () => {

        var addInfo = {}
        if (this.state.age !== '') {
            addInfo['age'] = this.state.age
        } 
        if (this.state.sex !== '') {
            addInfo['sex'] = this.state.sex
        } 
        if (this.state.name !== '') {
            addInfo['name'] = this.state.name
        }

        addInfo['token'] = localStorage.getItem('userToken')
      

        const response = await fetch(addLink, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json'
            },
            body: JSON.stringify(addInfo)
        });

        const data = await response.json();
        const status = data.status;

        if (status !== "false") {
            this.setState({
                createSuccess: true
            })
            var newProfile = data.profiles[data.profiles.length-1]
            newProfile.color = getRandomColor()
            profileList.push(newProfile)
            this.props.closeFunction()
        } else {
            this.state.failMessage = data.message
            this.setState({
                failMessage: data.message,
                createFail: true
            })
        }
    }
    render() {
        return (

            <IonContent>

                <IonToast
                    isOpen={this.state.createSuccess}
                    onDidDismiss={() => this.setState({ createSuccess: false })}
                    message="Successfully add the profile information"
                    duration={500}
                />
                <IonToast
                    isOpen={this.state.createFail}
                    onDidDismiss={() => this.setState({ createFail: false })}
                    message={this.state.failMessage}
                    duration={500}
                />



                <IonToolbar style={{ marginButtom: 0, }} >

                    <IonButtons  className = 'ModalBackButton'
                        onClick={() => this.props.closeFunction()}
                        slot='start'>
                        <IonIcon icon={chevronBackSharp}></IonIcon>

                    </IonButtons>

                </IonToolbar>
                <IonToolbar style={{ textAlign: 'center' }}>
                    <IonIcon icon={personAddOutline}
                        style={{
                            fontSize: '65px',
                        }}

                    />
                </IonToolbar>
                <IonCard className="profileDetailCard">
                    <IonLabel className='settingLabel' >Name</IonLabel>
                    <IonToolbar>

                        <IonInput
                            type='text'
                            id='name'
                            value={this.state.name}
                            className='AccountSettingInput'
                            required={true}
                            onIonChange={(e) => getDetail(e,this)} >
                        </IonInput>
                        <IonIcon slot='end' icon={pencilOutline}></IonIcon>
                    </IonToolbar>
                    <IonLabel className='settingLabel' >Age</IonLabel>
                    <IonToolbar>
                        <IonInput
                            className='AccountSettingInput'
                            id='age'
                            value={this.state.age}
                            onIonChange={(e) => getDetail(e,this)} />
                        <IonIcon slot='end' icon={pencilOutline}></IonIcon>
                    </IonToolbar>

                    <IonLabel className='settingLabel' >Sex</IonLabel>
                    <IonToolbar>
                        <IonInput
                            className='AccountSettingInput'
                            id='sex'
                            value={this.state.sex}
                            onIonChange={(e) => getDetail(e,this)} />
                        <IonIcon slot='end' icon={pencilOutline}></IonIcon>
                    </IonToolbar>
                </IonCard>
                <IonCard id='accountSettingButtonCard'>

                    <IonButton onClick={this.addProfile}><IonIcon icon={checkmarkOutline} >
                    </IonIcon>Submit</IonButton>

                </IonCard>

            </IonContent>

        )
    }
}
export default AddProfile;