import {
    IonCard, IonTextarea, IonSelect, IonSelectOption, IonItem,
    IonLabel, IonList, IonRadio, IonRadioGroup, IonInput, IonDatetime, IonRange, IonCheckbox, IonIcon, IonCardContent, IonCardHeader
} from '@ionic/react';
import { pencilOutline } from 'ionicons/icons';
import React, { Component } from 'react';

import './Question.css'
interface questionInterface {
    question: {
        question: string,
        id: string,
        order_in_survey: Number,
        type: string,
        required: Boolean,
        choices: string,
    },

    getChange: any,
    getCheckedValue: any,
    stopSwiper: any,
    startSwiper: any,
}

class Question extends Component<questionInterface>{
    state = {
        questionAnswer: {},
    }

    renderQuestion() {
        var type = this.props.question.type
        var choice = [""]

        if (type === "select" || type === "select-multiple"
            || type === "radio" || type === "slider") {
            choice = this.props.question.choices.split(",")

        }
        if (type === "select") {
            return (
                <IonSelect placeholder="Select One"
                    id={this.props.question.id}
                    onIonChange={this.props.getChange}
                    style={{ fontSize: "medium" }}

                >
                    {choice.map((choice: any, index: any) => {

                        return (
                            <IonSelectOption key={index} value={String(choice).trim()}>{String(choice).trim()}</IonSelectOption>
                        )
                    })}
                </IonSelect>
            )
        } else if (type === "select-multiple") {

            return (
                <IonList className='answerList'>
                    {choice.map((choice: any, index: any) => {
                        return (
                            <IonItem key={index} id='radioChoice' lines="none">
                                <IonCheckbox value={String(choice).trim()}
                                    slot='start' id={this.props.question.id}
                                    onIonChange={this.props.getCheckedValue}

                                />
                                <IonLabel>{String(choice).trim()}</IonLabel>

                            </IonItem>

                        )
                    })}

                </IonList>

            )

        } else if (type === "radio") {
            return (
                <IonList className='answerList'  >
                    <IonRadioGroup id={this.props.question.id}
                        onIonChange={this.props.getChange}
                    >
                        {choice.map((choice: any, index: any) => {
                            return (
                                <IonItem key={index} id='radioChoice' lines="none">
                                    <IonLabel>{choice}</IonLabel>
                                    <IonRadio slot="start" value={String(choice).trim()} />
                                </IonItem>
                            )
                        })}

                    </IonRadioGroup>


                </IonList>
            )
        } else if (type === "short-text") {
            return (
                <IonInput
                    maxlength={50}
                    id={this.props.question.id}
                    onIonChange={this.props.getChange}
                    class='questionAnswer' ><IonIcon icon={pencilOutline} style={{ marginLeft: '10px' }} />
                </IonInput>
            )
        }
        else if (type === "integer") {
            return (
                <IonInput
                    maxlength={50}
                    type='number'
                    id={this.props.question.id}
                    onIonChange={this.props.getChange}

                    class='questionAnswer' ><IonIcon icon={pencilOutline} style={{ marginLeft: '10px' }}></IonIcon>
                </IonInput>
            )
        } else if (type === "float") {
            return (
                <IonInput

                    maxlength={50}
                    type='number'
                    step="0.01"
                    id={this.props.question.id}
                    onIonChange={this.props.getChange}
                    class='questionAnswer' >
                    <IonIcon icon={pencilOutline} style={{ marginLeft: '10px' }}></IonIcon>
                </IonInput>
            )
        }
        else if (type === "date") {
            return (
                <IonDatetime
                    id={this.props.question.id}
                    onIonChange={this.props.getChange}
                    class='questionAnswer' >

                </IonDatetime>
            )
        }
        else if (type === "slider") {

            return (

                <IonRange
                    id={this.props.question.id}
                    onIonChange={this.props.getChange}
                    min={parseInt(choice[0])} max={parseInt(choice[1])}
                    pin={true}
                    step={2}
                    onIonBlur={this.props.startSwiper}
                    onIonFocus={this.props.stopSwiper}
                    color='primary'
                    style = {{
                        "--bar-height":'10px',
                        "--pin-color":'white'
                    }}
                >
                    <IonLabel slot="start">{parseInt(choice[0])}</IonLabel>
                    <IonLabel slot="end">{parseInt(choice[1])}</IonLabel>
                </IonRange>

            )
        }

        else {
            return (
                <div>

                    <IonTextarea
                    
                        rows={3}
                        placeholder="Please enter the answer here"
                        id={this.props.question.id}
                        onIonChange={this.props.getChange}
                        class='questionAnswer'>
                     
                    </IonTextarea>
                </div>

            )
        }

    }

    addRequiredSymbol(name:string) {
        name = this.props.question.order_in_survey + ". "+name

        if (this.props.question.required === true) {
            name += ' *'
        }
        return name
    }

    render() {


        return (
            <IonCard style={{ backgroundColor: "white",borderRadius: '30px', }}>

                <IonCardHeader style={{ fontSize: "medium" , textAlign:'left', marginLeft:'10px'}}> <b>{this.addRequiredSymbol(this.props.question.question)} </b></IonCardHeader>

                <IonCardContent>{this.renderQuestion()}</IonCardContent>
            </IonCard>


        )
    }
}
export default Question;