import React from 'react';
import { IonToolbar, IonButtons, IonIcon, IonTitle } from '@ionic/react';
import './BackButton.css'
import { chevronBackSharp } from 'ionicons/icons';
interface backButtonInterface {
    function:any,
    arg:any,
    title:any,
}
class backButton extends React.Component<backButtonInterface> {
    render() {
        return (

            <IonToolbar style={{ marginButtom: 0, }} >

                <IonButtons
                    onClick={() => this.props.function(this.props.arg)}
                    className='ModalBackButton'
                    slot='start'>
                    <IonIcon icon={chevronBackSharp}></IonIcon>

                </IonButtons>


                <IonTitle id='surveyTitle'>
                    {this.props.title}
                </IonTitle>
            </IonToolbar>

        )
    }
}

export default backButton;