import { IonButton, IonContent, IonItem, IonItemDivider, IonLabel, IonList, IonPage, IonToggle } from '@ionic/react';
import React from 'react';
import Header from '../components/Header'
import { pushList, subscribe, subscribeMidnight, unsubscribe, unsubscribeAll, unsubscribeMidnight } from '../functions/notificationFunction';



class NotificationSetting extends React.Component {
  getPollenUpdate = true
  getSurveyUpdate = true
  getThunderstormUpdate = true
  getNewsUpdate = true
  getMidnightUpdate = true

  constructor(props) {
    super(props);
    var list = pushList;
    if (list) {
      if (!list.pollen) this.getPollenUpdate = false
      if (!list.survey) this.getSurveyUpdate = false
      if (!list.thunderstorm) this.getThunderstormUpdate = false
      if (!list.news) this.getNewsUpdate = false
      if (!list.night) this.getMidnightUpdate = false
    }
  }
  props: any = {};

  subscribeSetting(topic) {
    var status
    switch (topic) {
      case 'pollen':
        this.getPollenUpdate = !this.getPollenUpdate
        status = this.getPollenUpdate
        break;
      case 'survey':
        this.getSurveyUpdate = !this.getSurveyUpdate
        status = this.getSurveyUpdate
        break;
      case 'thunderstorm':
        this.getThunderstormUpdate = !this.getThunderstormUpdate
        status = this.getThunderstormUpdate
        break;
      case 'news':
        this.getNewsUpdate = !this.getNewsUpdate
        status = this.getNewsUpdate
        break;
    }

    if (status) subscribe(topic)
    else unsubscribe(topic)

  }

  subscribeNight() {
    this.getMidnightUpdate = !this.getMidnightUpdate;
    if (this.getMidnightUpdate) subscribeMidnight();
    else unsubscribeMidnight();
  }

  render() {
    return (
      <IonPage>
        <Header title='Notification Setting' />
        <IonContent>
          <IonList>
            <IonItemDivider>Push Notification</IonItemDivider>
            <IonItem>
              <IonLabel>Pollen Update</IonLabel>
              <IonToggle checked={this.getPollenUpdate}
                onClick={() => this.subscribeSetting('pollen')} />
            </IonItem>
            <IonItem>
              <IonLabel>New Survey</IonLabel>
              <IonToggle checked={this.getSurveyUpdate}
                onClick={() => this.subscribeSetting('survey')} />
            </IonItem>
            <IonItem>
              <IonLabel>Thunderstorm Asthma Alert</IonLabel>
              <IonToggle checked={this.getThunderstormUpdate}
                onClick={() => this.subscribeSetting('thunderstorm')} />
            </IonItem>
            <IonItem>
              <IonLabel>Pollen News</IonLabel>
              <IonToggle checked={this.getNewsUpdate}
                onClick={() => this.subscribeSetting('news')} />
            </IonItem>
            <IonItem>
              <IonLabel>Receive message at midnight</IonLabel>
              <IonToggle checked={this.getMidnightUpdate}
                onClick={() => this.subscribeNight()} />
            </IonItem>

          </IonList>
        </IonContent>
      </IonPage>
    );
  }
};

export default NotificationSetting;