import React, { Component } from 'react';
import { IonGrid, IonRow, IonCol, IonInput, IonCardContent, IonCard, IonCardTitle, IonIcon, IonText, IonModal } from '@ionic/react';
import "./TodayForecast.css"
import 'c3/c3.css';
import c3 from "c3";

import { chevronForwardOutline, thermometerOutline, rainyOutline, flowerOutline, thunderstormOutline } from 'ionicons/icons';
import TodayWeather from './WeatherInfo/TodayWeather'
import AirQuality from './AirQuality/AirQuality'
import Thunderstorm from './Thunderstorm/Thunderstorm'
import { chooseColor, getLevelNumber } from '../../../functions/function';
interface todayLevel {
    pollenData: any,
    siteName: string,
    weatherData: any,
}

class todayForecast extends Component<todayLevel> {


    state = {
        wheatherDetail: false,
        airQualityDetail: false,
        thunderstormDetail: false,
    }

    componentDidMount() {
        this.renderChart()

    }
    componentDidUpdate() {
        this.renderChart()
    }

    renderChart() {
        c3.generate({
            bindto: "#todayLevelChart",
            data: {
                columns: [
                    ['Grass Pollen Count',
                        getLevelNumber(this.props.pollenData.pollen_level)]
                ],
                type: 'gauge',

            },
            gauge: {
                label: {
                    format: function (value, ratio) {
                        return '';
                    },
                    show: false
                },
                min: 0,
                max: 150,
                units: ' grains/m3',
            },
            legend: {
                hide: true,
            },
            tooltip: {
                show: false
            },
            color: {
                pattern: ['#60B044', '#F6C600', '#F97600', '#FF0000'],
                threshold: {
                    values: [20, 50, 100, 101]
                }
            },

            transition: {
                duration: null
            },
            size: {
                height: 100,
                width: 140,
            },

            interaction: {
                enabled: false
            },
            padding: {
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
            },
        });
    }



    closePop = (popName) => {
        if (popName === "weather") {
            this.setState({ wheatherDetail: false });
        } else if (popName === "air") {
            this.setState({ airQualityDetail: false });
        } else if (popName === "thunderstorm") {
            this.setState({ thunderstormDetail: false });
        }
    }


    render() {

        return (
            <IonGrid id='countGrid' >
                <IonModal isOpen={this.state.wheatherDetail}
                    onDidDismiss = {()=>this.setState({wheatherDetail:false})}
                    cssClass = 'surveyModal'>
                    <TodayWeather
                        wheatherData={this.props.weatherData}
                        returnBack={this.closePop} />
                </IonModal>
                <IonModal isOpen={this.state.airQualityDetail}
                    onDidDismiss = {()=>this.setState({airQualityDetail:false})}
                    cssClass = 'surveyModal'>
                    <AirQuality
                        wheatherData={this.props.weatherData}
                        returnBack={this.closePop} />
                </IonModal>
                <IonModal isOpen={this.state.thunderstormDetail}
                onDidDismiss = {()=>this.setState({thunderstormDetail:false})}
                    cssClass = 'surveyModal'>
                    <Thunderstorm
                        wheatherData={this.props.weatherData}
                        returnBack={this.closePop} />
                </IonModal>


                <IonRow id='locationRow' >
                    <IonCol >
                        <IonCard id='locationCard'>
                            <IonCardContent id='locationHint'>
                                Enter address or suburb
                            <IonInput id='placeHolder'
                                    placeholder='Parkvile,VIC 3010' />
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
                <IonRow className='forecastRow'>
                    <IonCol size='6' style={{ textAlign: "center" }}>
                        <div id="todayLevelChart"></div>

                        <IonText className='infoIntro'>Today's grass pollen forecast:</IonText>
                        <div><IonText id='forecastLevel'
                            style={{
                                color: chooseColor(this.props.pollenData.pollen_level),
                            }}>
                            {this.props.pollenData.pollen_level}
                        </IonText></div>
                    </IonCol>
                    <IonCol size='6' style={{ textAlign: "center" }}>
   
                        <p className='infoIntro'>
                            Yesterday’s count:
                            </p>
                        <p
                            id='lastLevel'
                            style={{
                                color: chooseColor(this.props.pollenData.pollen_level),
                            }}>
                            {this.props.pollenData.pollen_level}
                        </p>
                        <p className='infoIntro'>
                            Nearest pollen station:
                            </p>
                        <p id='site'>
                            {this.props.siteName}
                        </p>

                    </IonCol>
                </IonRow>
                <IonRow className='weatherRow' >
                    <IonCard
                        id='weatherCard'
                        class='weatherTodayCard'
                        onClick={() => { this.setState({ wheatherDetail: true }) }}>
                        <IonRow>
                            <IonCol className='weatherIntroCol'>
                                <IonCardTitle className='weatherInfoTitle'>
                                    <IonIcon icon={thermometerOutline} className='infoIcon' />
                                     Weather
                                </IonCardTitle>
                            </IonCol>
                            <IonCol>
                                <IonText className='detailText'>
                                    <IonIcon icon={rainyOutline} />
                                    {this.props.weatherData.min_temperture} ~ {this.props.weatherData.max_temperture}
                                </IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow  >
                            <IonText className='weatherInfo' text-wrap>
                                {this.props.weatherData.weather}
                                <IonIcon
                                    className='turnPageIcon'
                                    icon={chevronForwardOutline}
                                />
                            </IonText>
                        </IonRow>
                    </IonCard>


                    <IonCard id='weatherCard' class='weatherTodayCard'
                        onClick={() => { this.setState({ airQualityDetail: true }) }}>
                        <IonRow>
                            <IonCol className='weatherIntroCol'>
                                <IonCardTitle className='weatherInfoTitle'>
                                    <IonIcon icon={flowerOutline} className='infoIcon' />
                                    Air Quality

                        </IonCardTitle>
                            </IonCol>
                            <IonCol >
                                <IonText
                                    className='detailText'
                                    style={{
                                        color: chooseColor(this.props.weatherData.current_air_quality),
                                    }}
                                >
                                    {this.props.weatherData.current_air_quality}
                                </IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow >
                            <IonText className='weatherInfo'>
                                {this.props.weatherData.air_quality_site}
                                <IonIcon
                                    icon={chevronForwardOutline}
                                    className='turnPageIcon'
                                />
                            </IonText>
                        </IonRow>
                    </IonCard>


                    <IonCard id='Thunderstorm' class='weatherTodayCard'
                        onClick={() => { this.setState({ thunderstormDetail: true }) }}>
                        <IonRow>
                            <IonCol className='weatherIntroCol'>
                                <IonCardTitle className='weatherInfoTitle' >
                                    <IonIcon icon={thunderstormOutline} className='infoIcon' />

                                    Thunderstorm Asthma Risk
                                    </IonCardTitle>
                            </IonCol>
                            <IonCol>
                                <IonText
                                    className='detailText'
                                    style={{
                                        color: chooseColor(this.props.weatherData.thunderstorm_asthma_risk),
                                    }}
                                >
                                    {this.props.weatherData.thunderstorm_asthma_risk}
                                </IonText>
                            </IonCol>


                        </IonRow>
                        <IonRow >
                            <IonText className='weatherInfo'>
                                {this.props.weatherData.district}
                                <IonIcon
                                    icon={chevronForwardOutline}
                                    className='turnPageIcon'
                                />
                            </IonText>
                        </IonRow>
                    </IonCard>
                </IonRow>
            </IonGrid>
        )
    }

}
export default todayForecast