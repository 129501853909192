import {
  IonContent, IonPage, IonCard, IonCardTitle, IonCardContent, IonAlert, IonPopover, IonChip, IonLabel, IonButton, IonHeader, IonTitle, IonModal, IonToolbar,
} from '@ionic/react';
import React from 'react';
import Header from '../components/Header'
import './Survey.css'
import SignIn from '../components/Auth/SignIn'
import SurveyPage from '../components/Survey/SurveyPage'
import SurveyCard from '../components/Survey/SurveyCard'
import Register from '../components/Auth/Register'
import { getApiData, getInitial, isLogined, LogInByToken, profileList, profileUpdatedSurveyPage, selectedProfile, setProfileUpdateSurveyPage } from '../functions/function'
import SelectProfile from '../components/Survey/SelectProfile'

const surveyFormat = [{
  name: '',
  description: '',
  questions: [{
  }],
  ethical_disclaimer: null,
  expire_date: '',
}]



class Surveys extends React.Component {
  state = {
    login: false,
    spColor: null,
    showSignIn: false,
    surveyList: surveyFormat,
    surveyDetailShow: 0,
    showRegister: false,
    profiles: [],
    selectProfilePage: false,
    spInitial: '',
    tookAlert: false,
    unloginAlert: false,
  }


  //functions for decide whether need ethical disclamer
  precheck = (survey, index) => {
    var ED = 'ethicalDisclaimer' + survey.id
    var chooseIndex = "take" + survey.id;
    var localED = localStorage.getItem(ED)
    var sp = selectedProfile
    var doneSurveys = [] as Array<number>
    if (sp !== null) {
      doneSurveys = sp.done_survey
    }
    if (doneSurveys !== undefined && doneSurveys.includes(survey.id) && survey.mutiple_submit === false) {
      this.setState({ tookAlert: true })
      return
    } else if (sp === null && survey.need_profile === true) {
      this.setState({ unloginAlert: true })
      return
    } else if (localED !== 'true' && this.state.surveyList[index].ethical_disclaimer !== null) {
      this.setState({ [ED]: true })
    } else {
      this.setState({ [chooseIndex]: true })
    }
  }


  //functions for child component to close itself
  changeStatus = (register) => {
    this.setState({
      showSignIn: false,
      showRegister: register,
      login: false,
    })
    if (isLogined) this.setProfile()
  }

  closeRegister = () => {
    this.setState({ showRegister: false })
  }

  showSurveyPage = (chooseIndex) => {
    this.setState({ [chooseIndex]: false })
  }



  async componentDidMount() {
    const url = "https://portal.dev.pollenforecast.com.au/mobile-app/api/survey-api/";
    getApiData(url).then(res=>{
      this.setState({ surveyList: res  })
      if (res) {
        this.state.surveyList.map((survey: any, index: any) => {
          if (this.state[survey.id] !== false) {
            var chooseIndex = "take" + survey.id
            var ED = 'ethicalDisclaimer' + survey.id
            this.setState({ [chooseIndex]: false, [ED]: false })
          }
        })
      }
    }).catch(e=>console.log(e))

    if (!isLogined) await LogInByToken()
    if (isLogined) this.setProfile()
  }

  async componentDidUpdate() {
    if (profileUpdatedSurveyPage) {
      setProfileUpdateSurveyPage(false)
      this.setProfile()
    }

  }

  setProfile() {
    if (profileList !== null) {
      this.setState({
        login: true,
        profiles: profileList,
      })
    }
    this.checkSelectProfile()
  }

  checkSelectProfile() {
    var sptemp = selectedProfile
    var iniTemp = this.state.spInitial

    if (sptemp !== null) {
      if (this.state.spColor !== sptemp.color ||
        iniTemp !== getInitial(sptemp.name)) {
        this.setState({
          spInitial: getInitial(sptemp.name),
          spColor: sptemp.color
        })
      }

    }
  }


  acceptED(id) {
    var ED = 'ethicalDisclaimer' + id
    var chooseIndex = "take" + id;
    this.setState({ [ED]: false });
    localStorage.setItem(ED, "true")
    this.setState({ [chooseIndex]: true });
  }

  closeProfileSelet = () => {
    this.setState({ selectProfilePage: false })
    this.checkSelectProfile()

  }

  profile = () => {

    if (this.state.login === false) {
      return (
        <IonCard id='profileInformationCard'>
          <IonCardContent  >
            <div id='surveySignInText'>
              Would you like to <u id='surveySignIn' onClick={() => this.setState({ showSignIn: true })}>sign in</u> to track your survey record?
            </div>
            <br></br>
          </IonCardContent>
        </IonCard >

      )
    } else {
      return (
        <IonCard id='profileInformationCard'>
          <IonCardTitle className='settingLabel'>Select your profile:</IonCardTitle>
          <IonCardContent >
            <IonToolbar style={{
              textAlign: 'center'
            }}>
              <IonChip
                className='accountAva'
                style={{
                  background: this.state.spColor,

                }}><IonLabel id='userIniAccount'>{this.state.spInitial}</IonLabel></IonChip>
            </IonToolbar>
            <u onClick={() => this.setState({ selectProfilePage: true })}>Select another profile</u>
          </IonCardContent>
        </IonCard >

      )
    }
  }

  render() {
    return (
      <IonPage style={{ backgroundColor: '#f9f9f9' }}>

        <IonAlert isOpen={this.state.tookAlert}
          onDidDismiss={() => this.setState({ tookAlert: false })}
          header={'Already Finished'}
          message={'You already finished this survey.Thank you for your participation'}
          cssClass='surveyAlert'
          buttons={[
            {
              text: 'OK',
              handler: () => {
                this.setState({ tookAlert: false })
              }
            },

          ]}
        />

        <IonAlert isOpen={this.state.unloginAlert}
          onDidDismiss={() => this.setState({ unloginAlert: false })}
          header={'Need to log in'}
          message={'Sorry, please login to take this survey'}
          cssClass='surveyAlert'
          buttons={[
            {
              text: 'OK',
              handler: () => {
                this.setState({ unloginAlert: false })
              }
            },

          ]}
        />

        <Header title="Surveys" />

        <IonContent id='surveysContent'>

          {/*popover for signin */}
          <IonPopover cssClass='signInPop' isOpen={this.state.showSignIn}
            onDidDismiss={e => this.setState({ showSignIn: false })}
          >
            <SignIn closeFunction={this.changeStatus} />
          </IonPopover>

          {/*popover for select different profile */}
          <IonModal
            isOpen={this.state.selectProfilePage}
            onDidDismiss={() => this.setState({ selectProfilePage: false })}
          >
            <SelectProfile
              closeModal={this.closeProfileSelet}
            ></SelectProfile>

          </IonModal>


          <IonPopover cssClass='fullScreenPop' isOpen={this.state.showRegister}
            onDidDismiss={() => this.setState({ showRegister: false })}
          >
            <Register
              closeRegister={this.closeRegister}
            />
          </IonPopover>

          {/* popover for  ethical Disclamer */}
          {this.state.surveyList &&
            this.state.surveyList.map((survey: any, index: any) => {
              var ED = 'ethicalDisclaimer' + survey.id
              var chooseIndex = "take" + survey.id;
              return (
                <>
                  <IonPopover isOpen={this.state[ED]} key={index}
                    cssClass='fullScreenPop' onDidDismiss={e => this.setState({ [ED]: false })}>
                    <IonCard style={{ boxShadow: "none", textAlign: 'center' }}>
                      <IonHeader>
                        <IonTitle>
                          Ethical Disclaimer
                        </IonTitle>
                      </IonHeader>
                      <IonCardContent> {survey.ethical_disclaimer}</IonCardContent>
                      <IonButton onClick={() => { this.acceptED(survey.id) }}>Accept</IonButton>
                      <IonButton onClick={() => this.setState({ [ED]: false })}>Cancel</IonButton>
                    </IonCard>
                  </IonPopover>
                  <IonModal isOpen={this.state[chooseIndex]} key={index}
                    cssClass='surveyModal'
                    onDidDismiss={e => this.setState({ [chooseIndex]: false })}>
                    <SurveyPage survey={survey}
                      showSurveyPage={this.showSurveyPage}
                      index={chooseIndex} />
                  </IonModal>
                </>
              )
            })}


          {this.profile()}

          {/* Showing cards that display each survey */}
          <SurveyCard surveyList={this.state.surveyList} precheck={this.precheck} />


        </IonContent>
      </IonPage>
    );
  };
}

export default Surveys;