import 'c3/c3.css';
import c3 from "c3";
import React, { Component } from 'react'
import moment from 'moment';
import C3Chart from 'react-c3js';

interface Data {
    pollens: any[],
    symptoms: any[]
}
class CompareChart extends Component<Data> {

   constructor(props){
       super(props)
       this.setChartData()
   }
    getTimeArray() {
        var end = moment().add(-1, 'days')
        var start = moment().add(-this.props.symptoms.length+1, 'days')
        for (var a = [] as any, d = start.toDate(); d <= end.toDate(); d.setDate(d.getDate() + 1)) {
            a.push(moment(new Date(d)).format('YYYY-MM-DD'));
        }
        return a;
    }

    setChartData(){
        var time = this.getTimeArray()
        time.unshift("date");
        this.props.pollens.unshift("Pollen Level");
        this.props.symptoms.unshift("Symptom Level")
        this.chart.data.columns = [time,this.props.pollens, this.props.symptoms]
    }
   

    chart = {
        data: {
            x: 'date',
            columns: [
                this.getTimeArray().unshift("date"),
                this.props.pollens.unshift("Pollen Level"),
                this.props.symptoms.unshift("Symptom Level")
            ],
      
        },
        legend: {
            position: 'right'
        },
        axis: {
   
            x: {
                type: 'timeseries',
                tick: {
                    format:  '%m/%d',
                    rotate: 10,
            
                },
                height: 130
            },
            rotated: true
        },
        tooltip: {
            show: true
        },


        size: {
            height: window.innerHeight*0.80,
            width: window.innerWidth*0.85,
        },

        padding: {
            top: window.innerHeight*0.05,
            bottom: window.innerHeight*0.05,
            right: window.innerWidth*0.01,
            left: window.innerWidth/10,
        },
    }

    render() {
        return (
            
            <div style={{paddingTop:'5px'}}>
                <C3Chart id =  "compareChart"
                data={this.chart.data} 
                axis = {this.chart.axis}
                legend = {this.chart.legend}
                padding = {this.chart.padding}
                size = {this.chart.size}
                tootip = {this.chart.tooltip}
                />


            </div>
        )
    }
}
export default CompareChart;
