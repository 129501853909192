import { IonCard, IonCardTitle, IonCardContent, IonRow, IonToolbar, IonText, IonToggle, IonCol, IonIcon, IonButton, IonAlert, IonPopover } from '@ionic/react';
import { chatbubbleEllipsesOutline, createOutline, documentTextOutline, statsChartOutline } from 'ionicons/icons';
import moment from 'moment';
import React, { Component } from 'react';
import { pollenFornight, symptomsFornight } from '../../functions/dummyData';
import CompareChart from './CompareChart'
interface SurveyListInterface {
	surveyList: {
		name: any,
		description: any,
		questions: any,
		ethical_disclaimer: any,
		expire_date: any,
	}[],
	precheck: any,
}

const surveyIcon = [documentTextOutline, createOutline, chatbubbleEllipsesOutline, statsChartOutline]

class SurveyCard extends Component<SurveyListInterface>{

	state = {
		surveyDetailShow: 0,
		participateStatus: true,
		showWithdrawAlert: false,
		openGraph: false,
	}

	iconList = surveyIcon
	showDetailed = (survey, index) => {

		if (this.state.surveyDetailShow === survey.id) {
			return (

				<IonCard className='surveyDetailCard' key={index}>
					<IonCardTitle className='surveyDescrptionTitle'><b>{this.props.surveyList[index].name}</b></IonCardTitle>
					<IonCardContent className='surveyDescription'>
						<br></br>
						{this.props.surveyList[index].description}

					</IonCardContent>
					<br></br>
					<IonButton disabled={!this.state.participateStatus}
						onClick={() => this.props.precheck(survey, index)}> Take Survey</IonButton>
				</IonCard>
			)
		}

	}

	renderSurveyEntry = (survey, index) => {
		var icon;
		while (this.iconList.length < index) {
			icon = surveyIcon[Math.floor(Math.random() * surveyIcon.length)];
			this.iconList.push(icon)
		}
		icon = this.iconList[index]

		if (this.state.surveyDetailShow === survey.id) {

			this.showDetailed(survey, index)
			return (
				<IonCol key={index}>
					<IonCard className='surveyCard' style={{ background: '#476990' }}
						onClick={() => { this.setState({ surveyDetailShow: 0 }) }}>

						<IonCardContent id='surveyName' style={{ color: 'white' }}>
							<IonRow>
								<IonCol size='3' >
									<IonIcon id='surveyIcon' style={{ color: 'white' }} icon={icon}></IonIcon></IonCol>
								<IonCol size='8'>
									<p id='surveyName'>{this.props.surveyList[index].name}</p>
									<p id='surveyTime'>Vaild till: {moment(this.props.surveyList[index].expire_date).format('MMM DD YY ')}</p>
								</IonCol>
							</IonRow>
						</IonCardContent>

					</IonCard>
				</IonCol>
			)
		}


		else {
			return (
				<IonCol sizeMd='5' sizeLg='6' key={index}>
					<IonCard className='surveyCard' onClick={() => this.setState({ surveyDetailShow: survey.id })} >
						<IonCardContent>
							<IonRow>
								<IonCol size='3' >
									<IonIcon id='surveyIcon' icon={icon}></IonIcon></IonCol>
								<IonCol size='8'>
									<p id='surveyName'>{this.props.surveyList[index].name}</p>
									<p id='surveyTime'>Vaild till: {moment(this.props.surveyList[index].expire_date).format('MMM DD YY')}</p>

								</IonCol>
							</IonRow>
						</IonCardContent>
					</IonCard>
				</IonCol>
			)
		}

	}

	decideParticipate = () => {
		var status = this.state.participateStatus
		if (status === true) {
			this.setState({ showWithdrawAlert: true })
		} else {
			this.setState({ participateStatus: !status })
		}
	}


	render() {
		return (
			<div>
				<IonAlert isOpen={this.state.showWithdrawAlert}
					onDidDismiss={() => this.setState({ showWithdrawAlert: false })}
					subHeader={'Are you sure you want to withdraw from this study?'}
					message={'Describe what the data is used for and what it would mean for the user and the study to withdraw their responses.'}
					buttons={[
						{
							text: 'Withdraw from this survey',
							handler: () => {
								this.setState({
									showWithdrawAlert: false,
									participateStatus: false
								})

							}
						},
						{
							text: 'cancel',
							handler: () => {
								this.setState({
									showWithdrawAlert: false,
									participateStatus: true
								})
							}
						}
					]}
				/>

				{/* Popup for graph */}
				<IonPopover
					isOpen={this.state.openGraph}
					onDidDismiss={() => this.setState({ openGraph: false })}
					id='chartPopover'
				>
					<CompareChart
						pollens={pollenFornight}
						symptoms={symptomsFornight}
					/>
				</IonPopover>


				<IonCard style={{ boxShadow: 'none', backgroundColor: 'white', padding: '10px' }} >
					<IonCardTitle class='settingLabel'  >Choose a survey:</IonCardTitle>
					<IonCardContent className='subText' >
						Choose a survey to make a report or manage the survey settings for your selected profile.

						<IonRow id="surveyRow" >
							{this.props.surveyList && this.props.surveyList.map((survey: any, index: any) => {
								if (moment().isBefore(moment(survey.expire_date))) {
									return (
										this.renderSurveyEntry(survey, index)
									)
								}
							}
							)}
						</IonRow>

						{this.props.surveyList && this.props.surveyList.map((survey: any, index: any) => {
							return (
								this.showDetailed(survey, index)
							)
						}
						)}
						<IonToolbar >
							<IonText id='surveyDescrptionTitle' ><b>Survey Participation</b><br></br></IonText>
							<IonToggle
								id='participateStatus'
								checked={this.state.participateStatus} slot='end'
								onClick={() => this.decideParticipate()}></IonToggle>
						</IonToolbar>
						<IonText>
							Allow the information provided in your responses to be used for research,
							including profile data (e.g. age and sex).
						</IonText>

						<IonToolbar>
							<IonText id='surveyDescrptionTitle'><b>Location Data</b><br></br></IonText>
							<IonToggle checked={true} slot='end'></IonToggle>
						</IonToolbar>
						<IonText id='surveyDescription'>
							Send us your location data when submitting the survey so we can confirm your location.
						</IonText>
						<IonToolbar>
							<IonText id='surveyDescrptionTitle' ><b>Show my record</b><br></br></IonText>
							<IonButton slot='end' color='light' size='small'
								onClick={() => this.setState({ openGraph: true })}>
								Show me
							</IonButton>
						</IonToolbar>
						<IonText id='surveyDescription'>
							Show your history symptom compare to pollen level in a line chart for last 14 days
						</IonText>
					</IonCardContent>
				</IonCard>

			</div>
		)
	}
}
export default SurveyCard;