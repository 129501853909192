// Forecast page
// Api format for Pollen level
export const pollenLevelData = {
    current_timestamp: 1619633191.301289,
    disclaimer: "This is a private feed meant for designated consumption only. Please do not read from it if you have not been explicitly given permission by the University of Melbourne or its assigns. Copyright 2017. All rights reserved.",
    status: "OK",
    num_sites: 1,
    site_data: [{
        site_name: "Timbuktu",
        site_id: 27,
        forecasts: {
            forecasts: [{
                forecast_date: "2021-04-29",
                pollen_level: "Low",
                colour_code: "#4cd964",
                text_colour_code: "#ffffff"
            },
            {
                forecast_date: "2021-04-30",
                pollen_level: "Low",
                colour_code: "#4cd964",
                text_colour_code: "#ffffff"
            },
            {
                forecast_date: "2021-05-01",
                pollen_level: "Low",
                colour_code: "#4cd964",
                text_colour_code: "#ffffff"
            }],
            num_days: 3,
            status: "Ok",
            feed_type: "Forecast"
        },
        pollen_counts: {
            counts: [{
                count_date: "2021-04-29",
                pollen_level: "Low",
                colour_code: "#4cd964",
                text_colour_code: "#ffffff",
                grass_count: "null",
                total_count: "null",
                count_data_for_date: "2021-04-28"
            }],
            num_days: 1,
            status: "Ok",
            feed_type: "Detailed"
        }
    }]
}

// Api format for weather/air quality/thunderstorm asthma risk
export const weatherToday = {
    weather: 'Partly cloudy. Light winds.',
    max_temperture: '14',
    min_temperture: '5',
    weather_detail: 'High chance of showers early this morning easing to just a slight chance of a shower this afternoon with sunny periods developing. Light winds becoming S15 to 20 km/h in the middle of the day.',
    district: 'West and South Gippsland',
    air_quality_forecast: 'Moderate',
    air_quality_site: 'Hazelwood pondage',
    current_air_quality: 'Good',
    thunderstorm_asthma_risk: 'Low',
    weather_area: 'Melbourne, VIC',
    thunderstorm_asthma_map: 'http://docs.health.vic.gov.au/ewas/images/map/showmap.php?img=esta-today.png'
}

// Api format for "Forecast for the week" section
export const weatherThisWeek = {
    site_id: 27,
    site_name: "Timnuktu",
    forecast: [{
        "index": 1,
        "forecast_date": "2020-05-25",
        "pollen_level": "Low",
        "min_temperture": "4",
        "max_temperture": "16",
        "air_quality": "Moderate",
        "thunderstorm_asthma_risk": "Hazardous",
    },
    {
        "index": 2,
        "forecast_date": "2020-05-26",
        "pollen_level": "High",
        "min_temperture": "9",
        "max_temperture": "18",
        "air_quality": "Extreme",
        "thunderstorm_asthma_risk": "Low",
    },
    {
        "index": 3,
        "forecast_date": "2020-05-27",
        "pollen_level": "Low",
        "min_temperture": "9",
        "max_temperture": "17",
        "air_quality": "Low",
        "thunderstorm_asthma_risk": "Low"
    },
    {
        "index": 4,
        "forecast_date": "2020-05-28",
        "pollen_level": "Low",
        "min_temperture": "7",
        "max_temperture": "17",
        "air_quality": "Moderate",
        "thunderstorm_asthma_risk": "Low"
    }],
    "district": "West and South Gippsland"
}

// Api format for "news and event" section

export const newsFeed = 
{
    news: 
    [{
        title: "Wrapping up the 2020 grass pollen season",
        date: "2021-02-05", 
        image: "/images/z_5H6w_656gb5_0EVa4ZTQsHGz8=/391/fill-300x300/", 
        path: "/news-events/wrapping-2020-grass-pollen-season"}, 
     {
         title: "Farewell from Melbourne Pollen", 
        date: "2021-02-01",
        image: "/images/2U8taxv5vrZohwT0H9-DEqvPryI=/184/fill-300x300/",
        path: "/news-events/farewell-melbourne-pollen"}, 
     {
        title: "Your 2020 pollen season update", 
        date: "2020-12-21", 
        image: "/images/fhe2rk5TC7lRRQCYuD6ipYbkUH8=/382/fill-300x300/",
        path: "/news-events/your-2020-pollen-season-update"
    }, {
        title: "Thunderstorm Asthma - The Pollen Podcast Ep. 4", 
        date: "2020-11-25", 
        image: "/images/ZKhBFQMmBWzPnV4XCCCnUqKzpQ8=/366/fill-300x300/",
        path: "/news-events/thunderstorm-asthma-pollen-podcast-ep-4"
    }], 
        number: 4}

export var pollenFornight = Array.from({ length: 14 }, () => Math.floor(Math.random() * 100));
export var symptomsFornight = Array.from({ length: 14 }, () => Math.floor(Math.random() * 100));