import { IonContent, IonImg, IonPage } from '@ionic/react';
import React from 'react';
import Header from '../components/Header'
import { appSetting, getSetting } from '../functions/function';

class ConnectWithUs extends React.Component {


  async componentDidMount() {
    if (appSetting === undefined) await getSetting()
    if (appSetting !== undefined && appSetting.connect_with_us !== null) {
      this.setState({
        custContent: appSetting.connect_with_us,
        paragraphs: appSetting.connect_with_us.split('\r\n'),
        img1: appSetting.connect_with_us_img_1,
        img2: appSetting.connect_with_us_img_2,
        img3: appSetting.connect_with_us_img_3,
        custormize: true
      });
    }
  }
  state = {
    custormize: false,
    custContent: '',
    paragraphs: [''],
    img1: '',
    img2: '',
    img3: '',
  }
  render() {
    return (
      <IonPage>
        <Header title="Connect With Us" />

        <IonContent>
          {!this.state.custormize &&
            <div>
              It's Connect With Us page!
            </div>
          }

          {this.state.custormize &&
            <div style={{ marginLeft: '25px', marginRight: '25px', }}>
              <div className='settingText'>
                {this.state.paragraphs.map((paragraph, index) => {
                  return (
                    <p key={index} style={{marginBottom:'15px'}}>
                      {paragraph}
                    </p>
                  )
                })
                }
              </div>
              <div>
                {this.state.img1 !== null &&
                  <IonImg class='cooperatorImg' src={this.state.img1} />
                }
                {this.state.img2 !== null &&
                  <IonImg class='cooperatorImg' src={this.state.img2} />
                }
                {this.state.img3 !== null &&
                  <IonImg class='cooperatorImg' src={this.state.img3} />
                }
              </div>
            </div>
          }
        </IonContent>
      </IonPage>
    );
  }
};

export default ConnectWithUs;