
import React, { } from 'react';
import { IonButtons, IonChip, IonContent, IonIcon, IonLabel, IonModal, IonTitle, IonToolbar } from '@ionic/react';
import { chevronBackSharp } from 'ionicons/icons';
import AddProfile from '../../components/AccountSetting/AddProfile'
import { getInitial, profileList, setProfileUpdatedAccountPage, setSelectedProfile } from '../../functions/function'


interface SeletProfileInterface {
    closeModal: any
}

class SelectProfile extends React.Component<SeletProfileInterface> {

    state = {
        addNew: false,
        profiles: profileList,
        login: false,
    }

    selectProfile(profile) {
        setSelectedProfile(profile)
        this.props.closeModal()
    }

    closeAddNew = () => {
        this.setState({
            addNew: false,
            profiles:profileList
        })
        setProfileUpdatedAccountPage(true)

    }

    render() {
        return (

            <IonContent style={{ textAlign: 'center' }}>
                <IonModal

                    isOpen={this.state.addNew}
                    onDidDismiss={e => this.setState({ addNew: false })}
                >
                    <AddProfile
                        closeFunction={this.closeAddNew}
                    />
                </IonModal>
                <IonToolbar style={{ marginButtom: 0, textAlign: 'center' }} >
                    <IonButtons className='ModalBackButton'
                        onClick={() => this.props.closeModal()}
                        slot='start'>
                        <IonIcon icon={chevronBackSharp}></IonIcon>

                    </IonButtons>


                </IonToolbar>
                <IonToolbar style={{ marginButtom: 0, textAlign: 'center' }}>
                    <IonTitle>This report is for: </IonTitle>
                </IonToolbar>
                <div style={{ margin: '10px' }}>
                    {this.state.profiles.map((profile: any, index: any) => {
                        return (
                            <div
                                key={index}
                                style={{ margin: '10px', display: 'inline-block', textAlign: 'center', width: '100px' }}>
                                <IonChip id='additionalProfileChips'
                                    onClick={() => this.selectProfile(profile)}
                                    style={{
                                        backgroundColor: profile.color,
                                        height: "85px",
                                        width: "85px",
                                        borderRadius: "85px",
                                        fontSize: "xx-large",
                                        color: "white",
                                        display: "flex,inline-block",

                                    }}
                                >
                                    <IonLabel style={{ margin: 'auto' }}>
                                        { getInitial(profile.name)}
                                    </IonLabel></IonChip>
                                <p style={{ fontSize: 'small', fontStyle: 'IBM Plex Sans' }}>
                                    {profile.name}
                                </p>
                            </div>
                        )
                    })
                    }
                    <div style={{
                        margin: '10px',
                        display: 'inline-block',
                        textAlign: 'center',
                        width: '100px'
                    }}>
                        <IonChip id='additionalProfileChips'
                            style={{
                                backgroundColor: 'cornflowerblue',
                                height: "85px",
                                width: "85px",
                                borderRadius: "85px",
                                fontSize: "xx-large",
                                color: "white",
                                display: "flex,inline-block",
                            }}
                            onClick={() => this.setState({ addNew: true })}>
                            <IonLabel style={{ margin: 'auto', display: 'inline-block' }}>+
                             </IonLabel>
                        </IonChip>
                        <p style={{ fontSize: 'small', fontStyle: 'IBM Plex Sans' }}>
                            New Profile</p>
                    </div>
                </div>
            </IonContent >

        )
    };
};

export default SelectProfile;


