import Menu from './components/Menu';
import React, { useState } from 'react';
import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Route, Redirect } from 'react-router-dom';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import Forecast from './pages/Forecast';
import Help from './pages/Help';
import Surveys from './pages/Surveys';
import AboutThisApp from './pages/AboutThisApp';
import AccountSetting from './pages/AccountSetting';
import ConnectWithUs from './pages/ConnectWithUs';
import NotificationSetting from './pages/NotificationSetting';
import TodayWeather from'./components/Forecast/TodayForecast/WeatherInfo/TodayWeather';
import AirQuality from './components/Forecast/TodayForecast/AirQuality/AirQuality'
import Thunderstorm from './components/Forecast/TodayForecast/Thunderstorm/Thunderstorm'
import Register from './components/Auth/Register'

const App: React.FC = () => {

  const [selectedPage, setSelectedPage] = useState('');

  return (
    <IonApp>
      <IonReactRouter >
        <IonSplitPane contentId="main">
          <Menu selectedPage={selectedPage} />
          <IonRouterOutlet id="main">
            <Route exact path="/">
              {<Redirect to="/Forecast" />}
            </Route>
            <Route path='/Forecast' component={Forecast} exact />
            <Route path ='/Forecast/TodayWeather' component = {TodayWeather} exact />
            <Route path ='/Forecast/AirQuality' component = {AirQuality} exact />
            <Route path ='/Forecast/Thunderstorm' component = {Thunderstorm} exact />
            <Route path='/Help' component={Help} exact />
            <Route path='/AccountSetting' component={AccountSetting} exact />
            <Route path='/AboutThisApp' component={AboutThisApp} exact />
            <Route path='/ConnectWithUs' component={ConnectWithUs} exact />
            <Route path='/NotificationSetting' component={NotificationSetting} exact />
            <Route path='/Surveys' component={Surveys} exact />
            <Route path='/Register' component={Register} exact />
   
          
           
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};
export default App;
