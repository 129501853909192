
import {
    IonCard, IonCardContent,
    IonButton, IonSlides, IonSlide, IonContent, IonToolbar, IonIcon, IonButtons, IonTitle, IonCardHeader, IonPopover, IonCardTitle, IonFab, IonFabButton, IonToast, IonItem, IonLabel, IonList, IonRadio, IonRadioGroup, IonDatetime
} from '@ionic/react';
import { arrowBackOutline, arrowForwardOutline, arrowUpCircleOutline, chevronBackSharp } from 'ionicons/icons';
import React, { Component } from 'react';

import Bounce from 'react-reveal/Bounce';

import './SurveyPage.css'
import Question from './Question';
import { getDetail, profileList, selectedProfile } from '../../functions/function';

interface surveyInterface {
    survey: {
        id: number,
        name: String,
        description: String,
        questions: [{
            "id": 86,
            "question": "Describe your strength today",
            "order_in_survey": 1,
            "survey": 98,
            "type": "radio",
            "required": true,
            "choices": "Strong,Normal,Weak",
            "category": 63
        },],
        categories: [{
        }],
        ethical_disclaimer: String,
        choose_time: any,
    },
    showSurveyPage: any,
    index: String,
}

class SurveyPage extends Component<surveyInterface>{
    swiperRef: React.RefObject<HTMLIonSlideElement>;

    constructor(props) {
        super(props);
        this.swiperRef = React.createRef<HTMLIonSlideElement>();

    }

    state = {
        questionAnswer: {},
        stopSwiper: false,
        alert: false,
        returnMessage: '',
        submitStatus: '',
        render: false,
        errors: false,
        errorMessage: '',
        chooseTime: false,
        choosedTime: '',
    }

    getCheckValue = (e) => {
        var questionID = e.target.id
        var checkedValue: string = e.target.value
        var tempAnswerArray: string[] = []
        var answerList = this.state.questionAnswer

        if (questionID in answerList) {
            tempAnswerArray = answerList[questionID]
        }

        var pos = tempAnswerArray.indexOf(checkedValue)
        if (pos === -1) {
            tempAnswerArray.push(checkedValue)
        } else {
            tempAnswerArray.splice(pos, 1)
        }

        answerList[questionID] = tempAnswerArray
        this.setState({
            questionAnswer: answerList
        })


    }


    getDetail = (e) => {
        var questionID = e.target.id
        var questionAnswer = e.target.value

        var questionList = this.state.questionAnswer
        questionList[questionID] = questionAnswer
        this.setState({
            questionAnswer: questionList
        })
    }

    checkRequiredQuestion = () => {
        var questionAnswerList = this.state.questionAnswer
        var filled = true;
        this.props.survey.questions.map((question) => {

            if (question.required === true) {

                if (questionAnswerList[question.id] === undefined ||
                    questionAnswerList[question.id] === '') {

                    this.setState({
                        errorMessage: 'Please fill in No.' + question.order_in_survey + ' question before submitting',
                        errors: true
                    })
                    filled = false;
                    return
                }
            }
        })
        return filled;
    }

    handleSubmit = async (e) => {
        this.setState({ showLoading: true });
        const submitLink = "https://portal.dev.pollenforecast.com.au/mobile-app/survey/submit-answer/"

        if (this.checkRequiredQuestion() === true) {
            var answer = {}
            answer['survey'] = this.props.survey.id;
            answer['question_answer'] = this.state.questionAnswer
            var profile = selectedProfile
            if (profile !== null) {
                answer['profile'] = profile.id
            }
            if (this.state.choosedTime !== '') {

                var time = new Date(this.state.choosedTime).setDate(new Date().getDate() - 1)
                answer['choosedTime'] = new Date(time).toISOString()
            }

            const response = await fetch(submitLink, {
                method: 'POST',
                body: JSON.stringify(answer)
            })

            var data = await response.json()

            if (data.status === false) {
                this.setState({
                    errorMessage: data.message,
                    errors: true
                })

            }
            else {
                var sptemp = selectedProfile
                if (sptemp != null) {

                    sptemp.done_survey.push(this.props.survey.id)
                    var i = 0
                    if (profileList !== null) {
                        while (i < profileList.length) {
                            if (sptemp.id === profileList[i].id) {
                                profileList[i] = sptemp
                            }
                            i += 1;
                        }
                    }
                }


                this.setState({
                    submitStatus: data.status,
                    returnMessage: data.message,
                    alert: true,
                })
            }
        }
    }

    slideOption = {

        paginationType: 'progressbar',
        pagination: {
            el: '.swiper-pagination',
            type: 'progressbar'
        },
        init: true,
        autoHeight: true,
        observer: true,
        observeParents: true,

    }

    checkIfLast(index) {
        var numberOfSlide = this.props.survey.categories.length
        if (numberOfSlide === 1) {
            return (
                <IonButton type='button' onClick={this.handleSubmit} >Submit</IonButton>
            )
        }
        else if (index === 0) {

            return (
                <IonButton onClick={this.nextPage} color='light'>
                    <IonIcon icon={arrowForwardOutline} /></IonButton>
            )
        }

        else if (index === numberOfSlide - 1) {
            return (
                <div>
                    <IonButton onClick={this.previousPage} color='light'><IonIcon icon={arrowBackOutline} />
                    </IonButton>
                    <IonButton type='button' onClick={this.handleSubmit} >Submit</IonButton>
                </div>
            )
        } else {
            return (
                <div>
                    <IonButton onClick={this.previousPage} color='light'><IonIcon icon={arrowBackOutline} />
                    </IonButton>
                    <IonButton onClick={this.nextPage} color='light'>
                        <IonIcon icon={arrowForwardOutline} /></IonButton>
                </div>
            )
        }
    }

    stopSwiper() {
        var slides = document.getElementById('surveySlides') as HTMLIonSlidesElement
        if (slides !== undefined) {
            slides.lockSwipes(true)
        }
    }

    startSwiper() {
        var slides = document.getElementById('surveySlides') as HTMLIonSlidesElement
        if (slides !== undefined) {
            slides.lockSwipes(false)
        }
    }

    nextPage() {
        var slides = document.getElementById('surveySlides') as HTMLIonSlidesElement
        if (slides !== undefined) {
            slides.slideNext()
        }
    }

    previousPage() {
        var slides = document.getElementById('surveySlides') as HTMLIonSlidesElement
        if (slides !== undefined) {
            slides.slidePrev()
        }
    }


    toTop(event) {
        var content = document.getElementById("surveyPageContent") as HTMLIonContentElement

        if (content !== null) {
            content.scrollToTop()
        }

    }

    chooseTime(index) {

        if (this.props.survey.choose_time === true &&
            index === 0) {
            return (
                <IonCard style={{ backgroundColor: "white", borderRadius: '30px', }}>

                    <IonCardContent>
                        When did you feel like this?
                        <IonList className='answerList'  >
                            <IonRadioGroup value={this.state.chooseTime}
                                onIonChange={e => this.setState({ chooseTime: e.detail.value })}
                            >
                                <IonItem id='radioChoice' lines="none"
                                    style={{ display: 'inline-block' }}>
                                    <IonLabel>Now</IonLabel>
                                    <IonRadio slot="start" value={false} />
                                </IonItem>
                                <IonItem id='radioChoice' lines="none"
                                    style={{ display: 'inline-block' }}>
                                    <IonLabel>Yesterday</IonLabel>
                                    <IonRadio slot="start" value={true} />
                                </IonItem>
                            </IonRadioGroup>
                            {this.state.chooseTime === true &&


                                <IonDatetime
                                    style={{ fontSize: "medium", textAlign: 'center', width: '80vw', }}
                                    displayFormat="HH:mm"
                                    placeholder='12:00'

                                    id='choosedTime'
                                    onIonChange={(e) => { getDetail(e, this); }}
                                ></IonDatetime>

                            }


                        </IonList></IonCardContent>
                </IonCard>


            )
        }
    }



    render() {

        return (

            <IonContent id='surveyPageContent' >

                <IonFab
                    onClick={(e) => this.toTop(e)}
                    vertical="bottom" horizontal="end" slot="fixed">
                    <IonFabButton color="light">
                        <IonIcon icon={arrowUpCircleOutline}

                        />
                    </IonFabButton>
                </IonFab>
                <IonPopover
                    id='successfulPopover'
                    isOpen={this.state.alert}
                    onDidDismiss={() => this.setState({ alert: false })}  >
                    <IonCard id='successfulCard'>
                        <IonCardHeader><IonCardTitle>
                            {this.state.submitStatus}
                        </IonCardTitle></IonCardHeader>
                        <IonCardContent id='signInmessage'>
                            {this.state.returnMessage}
                            <br></br><br></br>
                            <IonButton id='successfulReturnButton'
                                onClick={() => {
                                    this.setState({ alert: false });
                                    this.props.showSurveyPage(this.props.index)
                                }}>
                                OK</IonButton>
                        </IonCardContent>
                    </IonCard>
                </IonPopover>

                <IonToast
                    isOpen={this.state.errors}
                    onDidDismiss={() => this.setState({ errors: false })}
                    message={this.state.errorMessage}
                    duration={1000}
                />

                <IonToolbar style={{ marginButtom: 0, }} >

                    <IonButtons id='surveyBackButton'
                        onClick={() => this.props.showSurveyPage(this.props.index)}
                        className='ModalBackButton'
                        slot='start'>
                        <IonIcon icon={chevronBackSharp}></IonIcon>

                    </IonButtons>


                    <IonTitle id='surveyTitle'>
                        {this.props.survey.name}
                    </IonTitle>
                </IonToolbar>



                <IonCard id='surveyCard' style={{ width: "100vw" }}>

                    <IonSlides pager={true}
                        id='surveySlides' options={this.slideOption}
                        style={{ width: "100vw" }}
                        onIonSlideNextStart={this.toTop}>

                        {this.props.survey.categories.map((category: any, index: any) => {
                            return (
                                <IonSlide key={index} id={'categorSlides' + index} class='categorSlide' ref={this.swiperRef} >

                                    <form id='surveyForm'>
                                        <IonCard style={{ backgroundColor: "white", borderRadius: '30px', }}>
                                            <IonCardHeader style={{ fontSize: "large" }}>  Category: {category.name}</IonCardHeader>
                                        </IonCard>
                                        {this.chooseTime(index)}
                                        {
                                            this.props.survey.questions.map((question: any, index: any) => {
                                                if (question.category === category.id) {
                                                    return (
                                                        <Bounce right key={index}>
                                                            <Question

                                                                question={question}
                                                                getChange={this.getDetail}
                                                                stopSwiper={this.stopSwiper}
                                                                startSwiper={this.startSwiper}
                                                                getCheckedValue={this.getCheckValue}
                                                            />
                                                        </Bounce>
                                                    )
                                                }
                                            })
                                        }

                                        <br></br>
                                        {this.checkIfLast(index)}
                                        <br></br>
                                    </form>


                                </IonSlide>
                            )
                        })}

                    </IonSlides>



                </IonCard>
            </IonContent>

        )
    }
}

export default SurveyPage;