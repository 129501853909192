import { IonContent, IonPage, IonList, IonItem, IonModal, IonIcon, } from '@ionic/react';
import { chevronForwardOutline } from 'ionicons/icons';
import React from 'react';
import Header from '../components/Header'
import InfoPage from '../components/Help/InfoPage';
import { getSetting } from '../functions/function';

class Help extends React.Component {

  state = {
    helpSection: [{
      order: -1,
      title: '',
      content: '',
      img: '',
    }]
  }
  async componentDidMount() {
    var setting;
    getSetting().then(res => {
      if (res === undefined) this.setState({ helpSection: undefined })
      else {
        setting = res
        this.setState({ helpSection: setting.help_info })
        this.state.helpSection.map((helpinfo, index) => {
          var string = 'helpInfo' + helpinfo.order
          this.state[string] = false;
        })
      }
    })
  }

  closeInfoModal = (order: number) => {
    var string = 'helpInfo' + order
    this.setState({ [string]: false })
  }
  render() {

    return (
      <IonPage>
        <Header title="Help" />
        <IonContent>
          <IonList style={{ marginRight: '10px' }}>

            {this.state.helpSection && this.state.helpSection.map((helpinfo, index) => {
              var string = 'helpInfo' + helpinfo.order
              return (
                <>
                  <IonModal isOpen={this.state[string]} key={index}
                    cssClass='surveyModal'
                    onDidDismiss={e => this.setState({ [string]: false })}
                  >

                    <InfoPage
                      title={helpinfo.title}
                      content={helpinfo.content}
                      order={helpinfo.order}
                      img={helpinfo.img}
                      closeFunction={this.closeInfoModal}
                    ></InfoPage>
                  </IonModal>
                  <IonItem id="help-item" key={index}
                    onClick={(() => { this.setState({ [string]: true }) })}>
                    <p style={{ fontWeight: 550 }}>{helpinfo.title}</p>
                    <IonIcon slot='end' icon={chevronForwardOutline}></IonIcon>
                  </IonItem>
                </>
              );
            })}

          </IonList>

        </IonContent>
      </IonPage >
    );
  }
};

export default Help;