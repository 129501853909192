import React from 'react';
import { IonPage, IonContent, IonCardHeader, IonCard, IonCardContent, IonCardTitle, IonText } from '@ionic/react';
import './InfoPage.css'
import BackButton from '../BackButton';
interface HelpInfoInterface {
    title: string,
    content: string,
    order: number,
    closeFunction: any
    img: string,
}

class InfoPage extends React.Component<HelpInfoInterface> {
    paragraphs = this.props.content.split('\r\n')

    render() {

        return (


            <IonContent>
                <BackButton function={this.props.closeFunction} arg={this.props.order} title={''} />
                <IonCard style={{ boxShadow: 'none' }}>
                    <IonCardHeader style={{ paddingTop: 0 }}>
                        <IonCardTitle style={{ fontWeight: 700, fontFamily: 'none' }}>
                            {this.props.title}
                        </IonCardTitle>
                    </IonCardHeader>
                    <br></br>
                    <IonCardContent>
                        {this.props.img !== null &&
                        <div style={{textAlign:'center'}}>
                            <img src={this.props.img} 
                                className='helpImg'/>
                        </div>
                        }

                        <div className='helpInfoContent'>
                            {this.paragraphs.map((value, index) => {
                                return (
                                    <div key={index} style={{ marginBottom: '15px' }}>
                                        {value}
                                    </div>
                                )
                            })}

                        </div>
                    </IonCardContent>
                </IonCard>
            </IonContent>

        )
    }
};
export default InfoPage;