const signInPage = "https://portal.dev.pollenforecast.com.au/mobile-app/auth/login"

export const AREA_ID = 1
export var profileList;
export var selectedProfile;
export var userEmail;
export var profileUpdatedAccountPage = false;
export var profileUpdatedSurveyPage = false
export var appSetting;
export var colorList = ['#69bb7b', '#68afbb', '#68afbb',
	'#e15151', '#9fc096', '#e193aa', '#0c7bcf', '#D2557B'
	, '#D99E68', '#B5AF7C', '#587EB7', '#7E8F58', '#4E597E'
]
export function getRandomColor() {
	var index = Math.floor(Math.random() * colorList.length);
	var color = colorList[index];
	colorList.splice(index, 1)
	return color
}
export var isLogined = false

export function assignAvatarColor(profiles) {

	if (typeof profiles[0].color !== 'string') {
		profiles.map((profile) => {
			profile.color = getRandomColor()
		})
		return profiles
	}
}

export async function getApiData(url: string) {
	var responseJson: any;
	await fetch(url).then((response) => {
		if (response.ok) {
			responseJson = response.json()
		} else {
			throw new Error('Something went wrong');
		}
	}).catch((error) => {
		console.log(error)
	});
	return responseJson
}

export async function getSetting() {

	var infoPage = "https://portal.dev.pollenforecast.com.au/mobile-app/api/setting-api/"
	getApiData(infoPage).then(res => {
		var json = res
		var defaultSetting;
		var tempSetting;
		json.map((setting: any) => {
			if (setting.area_id === 1) defaultSetting = setting
			if (setting.area_id === AREA_ID) tempSetting = setting
		})
		if (tempSetting !== null) {
			for (var key in tempSetting) {
				if (tempSetting[key] === null) {
					tempSetting[key] = defaultSetting[key]
				}
			}
			if (tempSetting.help_info.length === 0) {
				tempSetting.help_info = defaultSetting.help_info
			}
			appSetting = tempSetting

		} else appSetting = defaultSetting
		return appSetting
	}).catch(e => {
		return undefined
	})


}

export async function LogInByToken() {
	var token = localStorage.getItem('userToken')
	if (token !== null && token !== undefined) {
		try {
			await fetch(signInPage, {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-type': 'application/json'
				},
				body: JSON.stringify({
					token: localStorage.getItem('userToken'),
				})
			}).then((response) => response.json())
				.then((data) => {
					const status = data.status;
					if (status !== "false") {
						setLocalUserInfo(data)
						return true;
					}
				})
		} catch (e) {
			return false;
		}
	} else return false

}

export function setLocalUserInfo(data) {
	profileList = assignAvatarColor(data.profiles)
	selectedProfile = profileList[0]
	userEmail = data.email
	isLogined = true
}

export function logOut(cls) {
	localStorage.removeItem('userToken')
	isLogined = false
	cls.setState({ login: false })
	if (localStorage.getItem('readDisclaimer') === null) {
		localStorage.setItem('readDisclaimer', 'true')
	}
}

export function getDetail(e, cls) {
	cls.setState({
		[e.target.id]: e.target.value
	})
}


export function getInitial(name: string) {
	return name.substring(0, 2).toUpperCase();
}

export function setUserEmail(email: string) {
	userEmail = email
}

export function setSelectedProfile(profile: []) {
	selectedProfile = profile
}
export function setProfileUpdatedAccountPage(update) {
	profileUpdatedAccountPage = update
}
export function setProfileUpdateSurveyPage(update) {
	profileUpdatedSurveyPage = update
}

export function chooseColor(level: String) {
	if (level === "Low" || level === 'Good') {
		return '#60B044'
	} else if (level === "Moderate") {
		return '#F6C600'
	} else if (level === "High") {
		return '#F97600'
	} else if (level === 'Extreme') {
		return '#FF0000'
	} else if (level === 'Hazardous') {
		return '#4D0045'
	} else if (level === 'Unknown') {
		return 'null'
	}
	return 'null'
}

export function getLevelNumber(level: String) {

	if (level === "Low") {
		return 10
	} else if (level === "Moderate") {
		return 30
	} else if (level === "High") {
		return 60
	} else if (level === 'Extreme') {
		return 110
	} else if (level === 'Unknown') {
		return -2
	}
	return -2
}
